import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DefaultButton } from 'office-ui-fabric-react';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { postUser, putUser, setPageMode } from '../../redux/SystemAdmin/SystemAdminActions';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = props;

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.onClubChange = this.handleClubChange.bind(this);
    this.onIsSystemAdminChange = this.handleIsSystemAdminChange.bind(this);
  }

  componentDidMount() {
    // this.setState({ NewUserName: this.props.pagemode.UserName });
    this.setState(this.props);
  }

  handleCancel() {
    this.props.dispatch(setPageMode({ pageviewMode: 'ViewUsers', UserId: null, UserName: null, disableEdit: true, disableDelete: true,
    }));
  }

  handleChange(event) {

    switch (event.target.id) {
      case 'userName':
        this.setState(state => ({
          selectedUser: {
            ...state.selectedUser,
            userName: event.target.value,
          },
        }));
        break;
      case 'email':
        this.setState(state => ({
          selectedUser: {
            ...state.selectedUser,
            email: event.target.value,
          },
        }));
        break;
      case 'password':
        this.setState(state => ({
          selectedUser: {
            ...state.selectedUser,
            password: event.target.value,
          },
        }));
        break;
      case 'isSystemAdmin':
        this.setState(state => ({
          selectedUser: {
            ...state.selectedUser,
            isSystemAdmin: event.target.value,
          },
        }));
        break;
      default:

      // what value in state?
    }
    /*
    if (event.target.id === 'NewUserName') {
      this.setState({ NewUserName: event.target.value });
    }
    if (event.target.id === 'TextField50') {
      this.setState({ NewUserClub: event.target.value });
    } */
  }

  handleClubChange(event, item) {
    console.log(`Selection change: ${item.text} ${item.selected ? 'selected' : 'unselected'}`);

    const {userId} = this.state.selectedUser;

    // this.setState({ selectedItem: item });
    this.setState(state => ({
      selectedUser:
      {
        ...state.selectedUser,
        clubUsers:
          [{
            clubId: item.key,
            userId,
          },
          ],
      },
    }
    ));
  }

  handleIsSystemAdminChange(event, isSystemAdminChange) {
    this.setState(state => ({
      selectedUser: {
        ...state.selectedUser,
        isSystemAdmin: isSystemAdminChange,
      },
    }));
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.pagemode.pageviewMode === 'EditUser') {
      const { selectedUser } = this.state;

      this.props.dispatch(putUser(selectedUser));
    }

    if (this.props.pagemode.pageviewMode === 'NewUser') {
      this.props.dispatch(postUser(this.state.selectedUser));
    }
  }

  render() {
    const columnProps = {
      tokens: { childrenGap: 20 },
      styles: { root: { width: 250 } }
    };

    const { selectedUser } = this.state;
    const { pageviewMode, userName} = this.props.pagemode;

    const { clubs } = this.props;

    let clubOptions = clubs.map((thisClub, index) => {
      return (
        { key: thisClub.id, text: thisClub.name }
      );
    });

    let clubUser = null;
    if (typeof selectedUser.clubUsers[0] !== 'undefined') {
      clubUser = selectedUser.clubUsers[0];
    }





// { key: 'apple', text: 'Apple' }


    let userNameText = '';

    if (pageviewMode==='NewUser') {
      userNameText='Please enter name of User';
    }

    if (pageviewMode==='EditUser') {
      userNameText=this.state.selectedUser.userName;
    }

    // const {email, password, isSystemAdmin} = this.props.User;
    return (
      <div className="">
        <form className="userForm" id="userForm" onSubmit={this.handleSubmit}>
          <Stack
            horizontal tokens={{ childrenGap: 50 }}
            styles={{ root: { width: 650 } }}
          >
            <Stack {...columnProps}>
              {/* label="User Name" */}
              <TextField
                id="userName"
                label="UserName"
                value={this.state.selectedUser.userName}
                onChange={this.handleChange}
                defaultValue={this.state.selectedUser.userName}
                // placeholder={userNameText}
              />

              <TextField
                id="email"
                label="Email"
                value={this.state.selectedUser.email}
                onChange={this.handleChange}
                defaultValue={this.state.selectedUser.email}
                // placeholder={userNameText}
              />

              <TextField
                id="password"
                label="Password"
                value={this.state.selectedUser.password}
                onChange={this.handleChange}
                defaultValue={this.state.selectedUser.password}
                // placeholder={userNameText}
              />

              <Checkbox
                id="isSystemAdmin"
                label="IsSystemAdmin"
                checked={this.state.selectedUser.isSystemAdmin}
                onChange={this.onIsSystemAdminChange}
                defaultValue={this.state.selectedUser.isSystemAdmin}
              />

              <Dropdown
                label="Club"
                selectedKey={clubUser ? clubUser.clubId : undefined}
                onChange={this.onClubChange}
                placeholder="Select an option"
                options={clubOptions}
                styles={{ dropdown: { width: 300 } }}
              />

            </Stack>
            <Stack {...columnProps}>
              {/* <Label>Click Create to complete</Label> */}
              <DefaultButton
                type="submit"
                data-automation-id="test"
                allowDisabledFocus={true}
                text={pageviewMode}
              />
              <DefaultButton
                // type=""
                onClick={this.handleCancel}
                allowDisabledFocus={true}
                text="Cancel"
              />
            { /* <input type="submit" value="Submit" /> */ }
            </Stack>
          </Stack>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedUser: state.SystemAdminReducer.pagemode.selectedUser,
  loading: state.SystemAdminReducer.loading,
  error: state.SystemAdminReducer.error,
  pagemode: state.SystemAdminReducer.pagemode,
  clubs: state.SystemAdminReducer.clubs,
});


export default connect(mapStateToProps)(User);
