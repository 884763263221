import React, { Component } from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import {
  DetailsList,
  DetailsListLayoutMode,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';

import Section from './Section';


// eslint-disable-next-line react/prefer-stateless-function
class SectionsList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props, selectedSection: { id: 0 } };

    this.onSelectedSectionChanged = this.handleSelectedSectionChanged.bind(this);

    this.onCancelSectionClick = this.handleCancelSectionClick.bind(this);
    this.onSubmitSectionClick = this.handleSubmitSectionClick.bind(this);

    this.onViewSectionClick = this.handleViewSectionClick.bind(this);
  }

  componentDidMount() {
    // this.setState({ selectedSection: { sectionNo: 0 } });
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(nextProps);
    this.state = { ...nextProps, selectedSection: { id: 0 } };
  }

  handleViewSectionClick() {
    this.setState({ formMode: 'viewSection' });
  }

  handleNewSectionClick() {
    this.setState(
      {
        selectedSection: {
          id: 0,
          sectionNo: '',
          sectionName: 'new section',
          groupName: 'new group',
          observerKey: '',
        },
        formMode: 'newSection',
      },
    );


    // this.setState({ formMode: 'newSection' });
  }

  handleCancelSectionClick() {

    // this.setState({ formMode: 'viewSections' });
    this.setState(
      {
        selectedSection: {
          id: 0,
          sectionNo: '',
          sectionName: '',
          groupName: '',
          observerKey: '',
          eventId: '',
        },
        formMode: 'viewSections',
      },
    );


  }

  handleSubmitSectionClick(formMode, selectedSection) {
    // dispatch PUT to EventDetails
    // need logic to determine if update or new

    switch (formMode) {
      case 'newSection':
        this.props.onCreateSection(selectedSection);
        break;
      case 'editSection':
        this.props.onUpdateSection(selectedSection);
        break;
      case 'deleteSection':
        this.props.onDeleteSection(selectedSection);
        break;
      default:
        // 'viewSection'
    }
/*
    if (selectedSection.id === 0) {
      this.props.onCreateSection(selectedSection);
    } else
    {
      this.props.onUpdateSection(selectedSection);
    }
    */
    this.setState({ formMode: 'viewSections' });
  }

  handleEditSectionClick() {
    this.setState({ formMode: 'editSection' });
  }

  handleDeleteSectionClick() {
    this.setState({ formMode: 'deleteSection' });
  }

  handleSelectedSectionChanged(selectedSection) {
    this.setState({ selectedSection });
  }

  render() {
    const { eventSections } = this.state; //.props;
    const { selectedSection } = this.state;
    let activeComponent = '';
    const { formMode } = this.state;
    const { id } = selectedSection;

    let isNoSectionSelected;
    if (id === 0) {
      isNoSectionSelected = true;
    } else {
      isNoSectionSelected = false;
    }


    const SectionNo = id;

    const SectionsDetailsList = (
      <DetailsList
        checkboxVisibility={CheckboxVisibility.hidden}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick="true"
        setKey="SectionNo"
        // initialFocusedIndex={2}
        focusIndex={2}
        onActiveItemChanged={this.onSelectedSectionChanged}
        items={eventSections}
        columns={[
          { key: 'column1', name: 'Id', fieldName: 'id' },
          { key: 'column2', name: 'SectionNo', fieldName: 'sectionNo' },
          { key: 'column3', name: 'SectionName', fieldName: 'sectionName' },
          { key: 'column4', name: 'Group', fieldName: 'groupName' },
          { key: 'column5', name: 'ObserverKey', fieldName: 'observerKey' },
        ]}
        layoutMode={DetailsListLayoutMode.fixedColumns}
      />
    );

    // let SectionNo = eventSections.find(x => x.sectionNo === 2).id;

    switch (formMode) {
      case 'viewSections':
        activeComponent = (
          <React.Fragment>
            { /*<h3>View Sections: {SectionNo}</h3>*/}
            {SectionsDetailsList}
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'viewSection':
        activeComponent = (
          <React.Fragment>
            {/*<h3>View Section: {SectionNo}</h3>*/}
            <Section
              selectedSection={selectedSection}
              onCancelSectionClick={this.onCancelSectionClick}
              onSubmitSectionClick={this.onSubmitSectionClick}
              formMode={formMode}
              title="View Section"
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'newSection':
        activeComponent = (
          <React.Fragment>
            {/*<h3>New Section: {SectionNo}</h3>*/}
            <Section
              selectedSection={selectedSection}
              onCancelSectionClick={this.onCancelSectionClick}
              onSubmitSectionClick={this.onSubmitSectionClick}
              formMode={formMode}
              title="New Section"
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'editSection':
        activeComponent = (
          <React.Fragment>
            {/*<h3>Edit Section: {SectionNo}</h3>*/}
            <Section
              selectedSection={selectedSection}
              onCancelSectionClick={this.onCancelSectionClick}
              onSubmitSectionClick={this.onSubmitSectionClick}
              formMode={formMode}
              title="Edit Section"
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'deleteSection':
        activeComponent = (
          <React.Fragment>
            {/*<h3>Delete Section: {SectionNo}</h3>*/}
            <Section
              selectedSection={selectedSection}
              onCancelSectionClick={this.onCancelSectionClick}
              onSubmitSectionClick={this.onSubmitSectionClick}
              formMode={formMode}
              title="Delete Section"
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      default:
        activeComponent = 'UNKNOWN....';
    }


    return (
      <div className="">
        <CommandBar
          items={[
            {
              key: 'Section',
              text: 'SECTION',
            },
            {
              key: 'viewSection',
              text: 'View',
              iconProps: { iconName: 'View' },
              disabled: isNoSectionSelected,
              onClick: this.onViewSectionClick,
            },
            {
              key: 'newSection',
              text: 'New',
              iconProps: { iconName: 'Add' },
              disabled: false,
              onClick: () => this.handleNewSectionClick(),
            },
            {
              key: 'editSection',
              text: 'Edit',
              iconProps: { iconName: 'Edit' },
              disabled: isNoSectionSelected,
              onClick: () => this.handleEditSectionClick(),
            },
            {
              key: 'deleteSection',
              text: 'Delete',
              iconProps: { iconName: 'Delete' },
              disabled: isNoSectionSelected,
              onClick: () => this.handleDeleteSectionClick(),
            },
          ]
    }
        />

        {activeComponent}


      </div>
    );
  }
}

export default (SectionsList);
