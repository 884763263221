import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DefaultButton } from 'office-ui-fabric-react';
import { postClub, putClub, setPageMode } from '../redux/SystemAdmin/SystemAdminActions';


/* https://reactjs.org/docs/forms.html */


class Club extends Component {
  constructor(props) {
    super(props);
    this.state =
      { NewClubName: '',
        NewClubRegion: '' };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    //this.props.dispatch(fetchEventDetails());
    this.setState({ NewClubName: this.props.pagemode.ClubName });
  }

  handleCancel() {
    this.props.dispatch(setPageMode({ pageviewMode: 'ViewClubs', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
    }));

  }

  handleChange(event) {
    if (event.target.id === 'NewClubName') {
      this.setState({ NewClubName: event.target.value });
    }
    if (event.target.id === 'TextField50') {
      this.setState({ NewClubRegion: event.target.value });
    }
    //this.setState({ NewClubName: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.pagemode.pageviewMode === 'EditClub') {
      const updateClub = {
        Club: { id: this.props.pagemode.ClubId, name: this.state.NewClubName },
      }
      this.props.dispatch(putClub(updateClub));
    }

    if (this.props.pagemode.pageviewMode === 'NewClub') {
      this.props.dispatch(postClub(this.state.NewClubName));
    }


    //.then(this.props.dispatch(getClubs()));
      //following doesn't seem to refresh with most recent club POST

    //this.props.dispatch(getClubs());
/*
    this.props.dispatch(setPageMode({ pageviewMode: 'View', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
        }));
*/

  }

  render() {
    const columnProps = {
      tokens: { childrenGap: 20 },
      styles: { root: { width: 250 } }
    };

    const { pageviewMode, ClubName } = this.props.pagemode;

    let clubNameText = '';

    if (pageviewMode === 'NewClub') {
      // return <div>New!</div>;
      clubNameText='Please enter name of Club';
    }

    if (pageviewMode === 'EditClub') {
      //return <div>Edit!</div>;
      clubNameText=ClubName;
      //this.setState({ NewClubName: ClubName });
    }

    return (
      <div className="">
       {/* <h1>Club Create/Edit Form</h1> */ }

        <form className="clubForm" id="clubForm" onSubmit={this.handleSubmit}>
        <Stack horizontal tokens={{ childrenGap: 50 }} styles={{ root: { width: 650 } }}>
          <Stack {...columnProps}>
            {/* label="Club Name" */}
            <TextField id="NewClubName" value={this.state.ClubName} onChange={this.handleChange} defaultValue={ClubName} placeholder={clubNameText}/>
            {/*<TextField value={this.state.value} onChange={this.handleChange} label="Club Region" placeholder="Please enter Region of Club" /> */}
          </Stack>

          <Stack {...columnProps}>
            {/* <Label>Click Create to complete</Label> */}
            <DefaultButton
              type="submit"
              data-automation-id="test"
              allowDisabledFocus={true}
              text={pageviewMode}
            />
            <DefaultButton
              // type=""
              onClick={this.handleCancel}
              allowDisabledFocus={true}
              text="Cancel"
            />
            { /* <input type="submit" value="Submit" /> */ }
          </Stack>
        </Stack>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  club: state.SystemAdminReducer.club,
  loading: state.SystemAdminReducer.loading,
  error: state.SystemAdminReducer.error,
  pagemode: state.SystemAdminReducer.pagemode,
});


export default connect(mapStateToProps)(Club);
