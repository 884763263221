
// CLUBS
export const GET_CLUBS_BEGIN = 'GET_CLUBS_BEGIN';
export const GET_CLUBS_SUCCESS = 'GET_CLUBS_SUCCESS';
export const GET_CLUBS_FAILURE = 'GET_CLUBS_FAILURE';

export const POST_CLUB_BEGIN = 'POST_CLUB_BEGIN';
export const POST_CLUB_SUCCESS = 'POST_CLUB_SUCCESS';
export const POST_CLUB_FAILURE = 'POST_CLUB_FAILURE';

export const DELETE_CLUB_BEGIN = 'DELETE_CLUB_BEGIN';
export const DELETE_CLUB_SUCCESS = 'DELETE_CLUB_SUCCESS';
export const DELETE_CLUB_FAILURE = 'DELETE_CLUB_FAILURE';

export const PUT_CLUB_BEGIN = 'PUT_CLUB_BEGIN';
export const PUT_CLUB_SUCCESS = 'PUT_CLUB_SUCCESS';
export const PUT_CLUB_FAILURE = 'PUT_CLUB_FAILURE';

// USERS
export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const POST_USER_BEGIN = 'POST_USER_BEGIN';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';

export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const PUT_USER_BEGIN = 'PUT_USER_BEGIN';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE';

export const SET_PAGE_MODE = 'SET_PAGE_MODE';
