import {
  POST_SECTION_BEGIN,
  POST_SECTION_SUCCESS,
  POST_SECTION_FAILURE,
  PUT_SECTION_BEGIN,
  PUT_SECTION_SUCCESS,
  PUT_SECTION_FAILURE,
  DELETE_SECTION_BEGIN,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE,
} from './ClubAdminActionTypes';

// eslint-disable-next-line import/prefer-default-export

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function postSectionBegin(NewSectionName) {
  return {
    type: POST_SECTION_BEGIN,
    payload: { NewSectionName },
  };
}

export function postSectionSuccess(clubId) {
  return {
    type: POST_SECTION_SUCCESS,
    payload: { clubId },
  };
}

export function postSectionFailure(error) {
  return {
    type: POST_SECTION_FAILURE,
    payload: { error },
  };
}

export function postSection(NewSection) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ ...NewSection }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(postSectionBegin(NewSection));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Sections`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(postSectionSuccess(json));
        return json;
      })
      .catch(error => dispatch(postSectionFailure(error)));
  };
}

export function putSectionBegin(updateSection) {
  return {
    type: PUT_SECTION_BEGIN,
    payload: { updateSection },
  };
}

export function putSectionSuccess(updateSection) {
  return {
    type: PUT_SECTION_SUCCESS,
    payload: { updateSection },
  };
}

export function putSectionFailure(error) {
  return {
    type: PUT_SECTION_FAILURE,
    payload: { error },
  };
}

export function putSection(updateSection) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      body: JSON.stringify({ ...updateSection }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(putSectionBegin(updateSection));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Sections`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(putSectionSuccess(json));
        return json;
      })
      .catch(error => dispatch(putSectionFailure(error)));
  };
}

export function deleteSectionBegin(sectionId) {
  return {
    type: DELETE_SECTION_BEGIN,
    payload: { sectionId },
  };
}

export function deleteSectionSuccess(sectionId) {
  return {
    type: DELETE_SECTION_SUCCESS,
    payload: { sectionId },
  };
}

export function deleteSectionFailure(error) {
  return {
    type: DELETE_SECTION_FAILURE,
    payload: { error },
  };
}

export function deleteSection(sectionId) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      body: JSON.stringify({ id: sectionId }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(deleteSectionBegin(sectionId));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Sections/{sectionId}`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(deleteSectionSuccess(json));
        return json;
      })
      .catch(error => dispatch(deleteSectionFailure(error)));
  };
}