import React, { Component } from 'react';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DefaultButton } from 'office-ui-fabric-react';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
// import { Toggle } from 'office-ui-fabric-react/lib/Toggle';

class Class extends Component {

  constructor(props) {
    super(props);
    this.state = props;
    // this.state = { formMode: props.formMode };


    this.onSubmit = this.handleSubmit.bind(this);

    this.onCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onEditClassInDetailsClick = this.handleEditClassInDetailsClick.bind(this);
    /* this.onDeleteClassInDetailsClick = this.handleDeleteClassInDetailsClick.bind(this); */
  }

  componentDidMount() {
    // const {Event} = this.props;
    // this.setState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(nextProps);
    this.state = { ...nextProps };
  }

  handleEditClassInDetailsClick() {
    this.setState({ formMode: 'editClass' });
  }

  handleCancel() {
    this.props.onCancelClassClick();
    // this.setState({ formMode: 'viewClassInDetails' });
  }

  handleChange(event) {
    const { id } = event.target;

/*    if (id === 'EventName') {
      this.setState({ EventDetails: { name: event.target.value } });
    }
 */
    switch (id) {
      case 'name':
        this.setState(state => ({
          ClassDetails: {
            ...state.ClassDetails,
            className: event.target.value,
          },
        }));
        break;
      default:
      // unhandled formfield...
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { formMode } = this.state;
    const { ClassDetails } = this.state;
    // const paramSection = { formMode, ...selectedSection };

    this.props.onSubmitClassClick(formMode, ClassDetails);
    // this.setState({ formMode: 'viewEvents' });

  }

  render() {
    const {
      id,
      className,
      clubId,
    } = this.state.ClassDetails; // props.EventDetails;

    const {formMode} = this.state;
    let isViewOnly = true;
    let isDisableSubmit = false;
    let isDisableCancel = false;
    const columnProps = {
      tokens: { childrenGap: 5 },
      styles: { root: { width: 250 } }
    };

    let Menu = (
      <CommandBar
        items={[
          {
            key: 'ClassInDetails',
            text: 'CLASS',
          },
          {
            key: 'editClass',
            text: 'Edit Class',
            iconProps: { iconName: 'Edit' },
            onClick: () => this.onEditClassInDetailsClick(),
          },
        ]
      }
      />
    );

    switch (formMode) {
      case 'newClass':
        Menu = '';
        isViewOnly = false;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      case 'viewClass':
        isViewOnly = true;
        isDisableSubmit = true;
        isDisableCancel = true;
        break;
      case 'editClass':
        // Menu = '';
        isViewOnly = false;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      case 'deleteClass':
        isViewOnly = true;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      default:
        this.disableEventCommands = false;
    }

    return (
      <div className="">
        {Menu}
        { /* <h3>Form Mode: {formMode}</h3> */ }
        <form
          className="classForm"
          id="classForm"
          onSubmit={this.onSubmit}
          // onSubmit={this.props.onSubmit}
        >
          <Stack
            horizontal tokens={{ childrenGap: 50 }}
            styles={{ root: { width: 450 } }}
          >
            <Stack {...columnProps}>
              {/* label="User Name" */}

              <TextField
                id="name"
                label="ClassName"
                value={className}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />

              <TextField
                id="id"
                label="ClassId"
                value={id}
                onChange={this.handleChange}
                disabled="true"
              />
              <TextField
                id="clubId"
                label="ClubId"
                value={clubId}
                onChange={this.handleChange}
                disabled="true"
              />

            </Stack>
            <Stack {...columnProps}>
              {/* <Label>Click Create to complete</Label> */}
              <DefaultButton
                type="submit"
                data-automation-id="test"
                text="Submit"
                disabled={isDisableSubmit}
              />
              <DefaultButton
                // type=""
                // onClick="Cancel"
                onClick={this.onCancel}
                text="Cancel"
                disabled={isDisableCancel}
              />

            </Stack>
          </Stack>
        </form>
      </div>
    );
  }
}

export default (Class);
