import React, { Component } from 'react';

import {
  HashRouter,
} from 'react-router-dom';

import { Nav, INavLinkGroup } from 'office-ui-fabric-react/lib/Nav';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';

initializeIcons();

function _onRenderGroupHeader(group: INavLinkGroup): JSX.Element {
  return (<h3>{group.name}</h3>);
}

// eslint-disable-next-line react/prefer-stateless-function
class MainMenu extends Component {
  render() {
    return (
      <div>
      <HashRouter>
        <div>
          <Nav
            onRenderGroupHeader={_onRenderGroupHeader}
            groups={[
              {
                name: 'MENU',
                links: [
                  {
                    name: 'General', url: '#/', key: 'key1', target: '_self',
                  },
                  {
                    name: 'Club Admin', url: '#/clubadmin', key: 'key3', target: '_self',
                  },
                  {
                    name: 'Sys Admin', url: '#/Admin', key: 'key7', target: '_self',
                  },
                ],
              },
            ]}
          />
        </div>
      </HashRouter>
      </div>
    );
  }
}

export default MainMenu;
