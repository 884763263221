import React, { Component } from 'react';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DefaultButton } from 'office-ui-fabric-react';

class Section extends Component {

  constructor(props) {
    super(props);
    this.state = props;
    // this.state = { formMode: props.formMode };


    this.onCancel = this.handleCancel.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.handleSubmit.bind(this);
    // this.onEditSectionClick = this.handleEditSectionClick.bind(this);
    // this.onDeleteSectionClick = this.handleDeleteSectionClick.bind(this);
  }

  componentDidMount() {
    // const {Event} = this.props;
    this.setState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleCancel() {
    this.props.onCancelSectionClick();
    // this.setState({ formMode: 'viewSection' });
  }

  handleChange(event) {
    const { id } = event.target;

    if (id === 'sectionNo') {
      this.setState(state => ({
        selectedSection: {
          ...state.selectedSection,
          sectionNo: event.target.value,
        },
      }));
    }
    if (id === 'sectionName') {
      this.setState(state => ({
        selectedSection: {
          ...state.selectedSection,
          sectionName: event.target.value,
        },
      }));
    }
    if (id === 'groupName') {
      this.setState(state => ({
        selectedSection: {
          ...state.selectedSection,
          groupName: event.target.value,
        },
      }));
    }
    if (id === 'observerKey') {
      this.setState(state => ({
        selectedSection: {
          ...state.selectedSection,
          observerKey: event.target.value,
        },
      }));
    }

    //check state...
  }

  handleCheckboxChange(event, isChecked) {
    // alert('CheckboxChanged: ' + isChecked)
    const { Event } = this.state;
    Event.IsPublicView = isChecked;

    this.setState(
      {
        Event,
      },
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    const { formMode } = this.state;
    const { selectedSection } = this.state;
    // const paramSection = { formMode, ...selectedSection };

    this.props.onSubmitSectionClick(formMode, selectedSection);
    /*
    switch (formMode) {
      case 'newSection':
        this.props.onSubmitSectionClick(selectedSection);
      break;
      case 'editSection':
        this.props.onSubmitSectionClick(selectedSection);
      break;
      case 'deleteSection':
        this.props.onSubmitSectionClick(selectedSection);
      break;
    default:
        // 'viewSection'
    }
    */
  }

  render() {
    const { id, sectionNo, sectionName, groupName, observerKey } = this.state.selectedSection; // props.EventDetails;
    const { formMode } = this.state;
    let isViewOnly = true;
    let isDisableSubmit = false;
    let isDisableCancel = false;

    const columnProps = {
      tokens: { childrenGap: 5 },
      styles: { root: { width: 250 } },
    };


    // const { selectedItem } = { key: 'grape', text: 'Grape' };

    switch (formMode) {
      case 'newSection':
        isViewOnly = false;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      case 'viewSection':
        isViewOnly = true;
        isDisableSubmit = true;
        isDisableCancel = false;
        break;
      case 'editSection':
        isViewOnly = false;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      case 'deleteSection':
        isViewOnly = true;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      default:
        this.disableEventCommands = false;
    }

    return (
      <div className="">
       {/*} <h3>
          Form Mode:
          {formMode}
    </h3> */}
        <form
          className="sectionForm"
          id="sectionForm"
          onSubmit={this.onSubmit}
        >
          <Stack
            horizontal tokens={{ childrenGap: 50 }}
            styles={{ root: { width: 450 } }}
          >
            <Stack {...columnProps}>
              {/* label="User Name" */}

              <TextField
                id="sectionNo"
                label="SectionNo"
                value={sectionNo}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />
              <TextField
                id="sectionName"
                label="SectionName"
                value={sectionName}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />
              <TextField
                id="groupName"
                label="GroupName"
                value={groupName}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />
              <TextField
                id="observerKey"
                label="ObserverKey"
                value={observerKey}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />
              <TextField
                id="sectionId"
                label="id"
                value={id}
                onChange={this.handleChange}
                disabled="true"
              />

            </Stack>
            <Stack {...columnProps}>
              {/* <Label>Click Create to complete</Label> */}
              <DefaultButton
                type="submit"
                data-automation-id="test"
                text="Submit"
                disabled={isDisableSubmit}
              />
              <DefaultButton
                // type=""
                // onClick="Cancel"
                onClick={this.props.onCancelSectionClick}
                text="Cancel"
                disabled={isDisableCancel}
              />
            </Stack>
          </Stack>
        </form>
      </div>
    );
  }
}

export default (Section);
