import React, { Component } from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import {
  DetailsList,
  DetailsListLayoutMode,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';

import Entry from './Entry'
// eslint-disable-next-line react/prefer-stateless-function
class EntriesList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props, selectedEntry: { id: 0 } };

    this.onSelectedEntryChanged = this.handleSelectedEntryChanged.bind(this);

    this.onCancelEntryClick = this.handleCancelEntryClick.bind(this);
    this.onSubmitEntryClick = this.handleSubmitEntryClick.bind(this);

    this.onViewEntryClick = this.handleViewEntryClick.bind(this);
  }

  componentDidMount() {
    // this.setState({ selectedEntry: { sectionNo: 0 } });
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(nextProps);
    this.state = { ...nextProps, selectedEntry: { id: 0 } };
  }

  handleViewEntryClick() {
    this.setState({ formMode: 'viewEntry' });
  }

  handleNewEntryClick() {
    this.setState(
      {
        selectedEntry: {
          id: 0,
          riderNumber: 'new no',
          riderName: 'new rider',
          classId: null,
        },
        formMode: 'newEntry',
      },
    );


    // this.setState({ formMode: 'newEntry' });
  }

  handleCancelEntryClick() {

    // this.setState({ formMode: 'viewEntries' });
    this.setState(
      {
        selectedEntry: {
          id: 0,
          riderNumber: null,
          riderName: null,
          classId: null,
        },
        formMode: 'viewEntries',
      },
    );


  }

  handleSubmitEntryClick(formMode, selectedEntry) {
    // dispatch PUT to EventDetails
    // need logic to determine if update or new

    switch (formMode) {
      case 'newEntry':
        this.props.onCreateEntry(selectedEntry);
        break;
      case 'editEntry':
        this.props.onUpdateEntry(selectedEntry);
        break;
      case 'deleteEntry':
        this.props.onDeleteEntry(selectedEntry);
        break;
      default:
        // 'viewEntry'
    }
    this.setState({ formMode: 'viewEntries' });
  }

  handleEditEntryClick() {
    this.setState({ formMode: 'editEntry' });
  }

  handleDeleteEntryClick() {
    this.setState({ formMode: 'deleteEntry' });
  }

  handleSelectedEntryChanged(selectedEntry) {
    this.setState({ selectedEntry });
  }

  render() {
    const { eventEntries } = this.state; // this.props;
    const { selectedEntry } = this.state;
    let activeComponent = '';
    const { formMode } = this.state;
    const { id } = selectedEntry;

    let isNoEntrySelected;
    if (id === 0) {
      isNoEntrySelected = true;
    } else {
      isNoEntrySelected = false;
    }


    // const EntryNo = id;

    const EntriesDetailsList = (
      <DetailsList
        checkboxVisibility={CheckboxVisibility.hidden}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick="true"
        setKey="EntryNo"
        // initialFocusedIndex={2}
        // focusIndex={2}
        onActiveItemChanged={this.onSelectedEntryChanged}
        items={eventEntries}
        columns={[
          { key: 'column1', name: 'EntryId', fieldName: 'id' },
          { key: 'column2', name: 'RiderNo', fieldName: 'riderNumber' },
          { key: 'column3', name: 'RiderName', fieldName: 'riderName' },
          { key: 'column4', name: 'ClassName', fieldName: 'className' },
          { key: 'column5', name: 'Class', fieldName: 'classId' },
        ]}
        layoutMode={DetailsListLayoutMode.fixedColumns}
      />
    );

    // let EntryNo = eventEntries.find(x => x.sectionNo === 2).id;

    switch (formMode) {
      case 'viewEntries':
        activeComponent = (
          <React.Fragment>
            { /* <h3>View Entries: {EntryNo}</h3> */ }
            {EntriesDetailsList}
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'viewEntry':
        activeComponent = (
          <React.Fragment>
            {/* <h3>View Entry: {EntryNo}</h3> */ }
            <Entry
              selectedEntry={selectedEntry}
              eventClasses={this.props.eventClasses}
              onCancelEntryClick={this.onCancelEntryClick}
              onSubmitEntryClick={this.onSubmitEntryClick}
              formMode={formMode}
              title="View Entry"
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'newEntry':
        activeComponent = (
          <React.Fragment>
            {/*<h3>New Entry: {EntryNo}</h3>*/}
            <Entry
              selectedEntry={selectedEntry}
              eventClasses={this.props.eventClasses}
              onCancelEntryClick={this.onCancelEntryClick}
              onSubmitEntryClick={this.onSubmitEntryClick}
              formMode={formMode}
              title="New Entry"
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'editEntry':
        activeComponent = (
          <React.Fragment>
            {/*<h3>Edit Entry: {EntryNo}</h3>*/}
            <Entry
              selectedEntry={selectedEntry}
              eventClasses={this.props.eventClasses}
              onCancelEntryClick={this.onCancelEntryClick}
              onSubmitEntryClick={this.onSubmitEntryClick}
              formMode={formMode}
              title="Edit Entry"
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'deleteEntry':
        activeComponent = (
          <React.Fragment>
            {/*<h3>Delete Entry: {EntryNo}</h3>*/}
            <Entry
              selectedEntry={selectedEntry}
              eventClasses={this.props.eventClasses}
              onCancelEntryClick={this.onCancelEntryClick}
              onSubmitEntryClick={this.onSubmitEntryClick}
              formMode={formMode}
              title="Delete Entry"
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      default:
        activeComponent = 'UNKNOWN....';
    }

    return (
      <div className="">
        <CommandBar
          items={[
            {
              key: 'Entry',
              text: 'ENTRY',
            },
            {
              key: 'viewEntry',
              text: 'View',
              iconProps: { iconName: 'View' },
              disabled: isNoEntrySelected,
              onClick: this.onViewEntryClick,
            },
            {
              key: 'newEntry',
              text: 'New',
              iconProps: { iconName: 'Add' },
              disabled: false,
              onClick: () => this.handleNewEntryClick(),
            },
            {
              key: 'editEntry',
              text: 'Edit',
              iconProps: { iconName: 'Edit' },
              disabled: isNoEntrySelected,
              onClick: () => this.handleEditEntryClick(),
            },
            {
              key: 'deleteEntry',
              text: 'Delete',
              iconProps: { iconName: 'Delete' },
              disabled: isNoEntrySelected,
              onClick: () => this.handleDeleteEntryClick(),
            },
          ]
    }

        />

        {activeComponent}
      </div>
    );
  }
}

export default (EntriesList);
