// export const GET_EVENTDETAILS = 'GET_EVENTDETAILS';
// export const POST_EVENTDETAILS = 'POST_EVENTDETAILS';

// align naming to API methods...
// GET    read
// POST   create
// PUT    update-replace
// PATCH  update-modify - do not use....
// DELETE delete

export const SET_PAGE_MODE = 'SET_PAGE_MODE';

export const GET_CLUBEVENTS_BEGIN = 'GET_CLUBEVENTS_BEGIN';
export const GET_CLUBEVENTS_SUCCESS = 'GET_CLUBEVENTS_SUCCESS';
export const GET_CLUBEVENTS_FAILURE = 'GET_CLUBEVENTS_FAILURE';

export const GET_EVENTDETAILS_BEGIN = 'GET_EVENTDETAILS_BEGIN';
export const GET_EVENTDETAILS_SUCCESS = 'GET_EVENTDETAILS_SUCCESS';
export const GET_EVENTDETAILS_FAILURE = 'GET_EVENTDETAILS_FAILURE';

export const POST_SECTION_BEGIN = 'POST_SECTION_BEGIN';
export const POST_SECTION_SUCCESS = 'POST_SECTION_SUCCESS';
export const POST_SECTION_FAILURE = 'POST_SECTION_FAILURE';

export const DELETE_SECTION_BEGIN = 'DELETE_SECTION_BEGIN';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE';

export const PUT_SECTION_BEGIN = 'PUT_SECTION_BEGIN';
export const PUT_SECTION_SUCCESS = 'PUT_SECTION_SUCCESS';
export const PUT_SECTION_FAILURE = 'PUT_SECTION_FAILURE';

export const POST_ENTRY_BEGIN = 'POST_ENTRY_BEGIN';
export const POST_ENTRY_SUCCESS = 'POST_ENTRY_SUCCESS';
export const POST_ENTRY_FAILURE = 'POST_ENTRY_FAILURE';

export const DELETE_ENTRY_BEGIN = 'DELETE_ENTRY_BEGIN';
export const DELETE_ENTRY_SUCCESS = 'DELETE_ENTRY_SUCCESS';
export const DELETE_ENTRY_FAILURE = 'DELETE_ENTRY_FAILURE';

export const PUT_ENTRY_BEGIN = 'PUT_ENTRY_BEGIN';
export const PUT_ENTRY_SUCCESS = 'PUT_ENTRY_SUCCESS';
export const PUT_ENTRY_FAILURE = 'PUT_ENTRY_FAILURE';

export const POST_EVENT_BEGIN = 'POST_EVENT_BEGIN';
export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS';
export const POST_EVENT_FAILURE = 'POST_EVENT_FAILURE';

export const DELETE_EVENT_BEGIN = 'DELETE_EVENT_BEGIN';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const PUT_EVENT_BEGIN = 'PUT_EVENT_BEGIN';
export const PUT_EVENT_SUCCESS = 'PUT_EVENT_SUCCESS';
export const PUT_EVENT_FAILURE = 'PUT_EVENT_FAILURE';
