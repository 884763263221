import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';

import SectionsList from './SectionsList';
import EntriesList from './EntriesList';
import EventInDetails from './EventInDetails';
import EventScores from '../EventScores/EventScores';

import { getEventDetails } from '../../redux/ClubAdmin/ClubAdminActions';

import {postEntry, putEntry, deleteEntry } from '../../redux/ClubAdmin/ClubAdmin_EntryActions';
import {postSection, putSection, deleteSection } from '../../redux/ClubAdmin/ClubAdmin_SectionActions';
import {postEvent, putEvent, deleteEvent } from '../../redux/ClubAdmin/ClubAdmin_EventActions';


class EventDetails extends Component {

  constructor(props) {
    super(props);
    this.state = { formMode: 'viewEventInDetails' };

    this.onViewScoresClick = this.handleViewScoresClick.bind(this);

    this.onViewSectionsClick = this.handleViewSectionsClick.bind(this);
    this.onViewEntriesClick = this.handleViewEntriesClick.bind(this);
    this.onViewEventClick = this.handleViewEventClick.bind(this);

    // this.onCreateEvent = this.handleCreateEvent.bind(this);
    this.onSubmitEventClick = this.handleSubmitEventClick.bind(this);
    this.onCancelEventClick = this.handleCancelEventClick.bind(this);

    this.onCreateSection = this.handleCreateSection.bind(this);
    this.onUpdateSection = this.handleUpdateSection.bind(this);
    this.onDeleteSection = this.handleDeleteSection.bind(this);
    this.onCreateEntry = this.handleCreateEntry.bind(this);
    this.onUpdateEntry = this.handleUpdateEntry.bind(this);
    this.onDeleteEntry = this.handleDeleteEntry.bind(this);
  }

  componentDidMount() {
    const { EventId } = this.props;
    this.props.dispatch(getEventDetails(EventId));
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(nextProps);
  //  this.state = { ...nextProps, selectedSection: { id: 0 } };
  /*  if (nextProps.EventDetails != this.props.EventDetails) {
      this.setState({ needsRenderHash: Math.random() });
    }
    */
  }

  handleSubmitEventClick(formMode, EventDetails) {
    // alert('UpdateEvent in EventDetails');
    const { id } = this.props.EventDetails;
    switch (formMode) {
      case 'createEvent':
      case 'createEventInDetails':
        this.props.dispatch(postEvent(EventDetails))
          .then(() => (this.props.dispatch(getEventDetails(id))));
        break;
      case 'editEventInDetails':
        this.props.dispatch(putEvent(EventDetails))
          .then(() => (this.props.dispatch(getEventDetails(id))));
        break;
      case 'deleteEventInDetails':
        this.props.dispatch(deleteEvent(id))
          .then(() => (this.props.dispatch(getEventDetails(id))));
        break;
      default:
        // unknown formMode
    }
    // this.setState({ formMode: 'viewEventInDetails' });
  }

  handleCancelEventClick(formMode, EventDetails) {
    // alert('Cancel Event in EventDetails');
    this.setState({ formMode: 'viewEventInDetails' });
  }

  handleCreateSection(selectedSection) {
    // alert('Create Section:' + selectedSection.sectionName);
    // dispatch...(add eventId (id)) - this.props.EventDetails.id
    const insertSection = selectedSection;
    const { id } = this.props.EventDetails;
    insertSection.eventId = id;

    this.props.dispatch(postSection(insertSection))
      .then(() => (this.props.dispatch(getEventDetails(id))));
  }

  handleUpdateSection(selectedSection) {
    // alert('Update Section:' + selectedSection.sectionName);
    // dispatch...
    const { id } = this.props.EventDetails;

    this.props.dispatch(putSection(selectedSection))
      .then(() => (this.props.dispatch(getEventDetails(id))));
  }

  handleDeleteSection(selectedSection) {
    // alert('Delete Section:' + selectedSection.sectionName);
    // dispatch...
    const { id } = this.props.EventDetails;

    this.props.dispatch(deleteSection(selectedSection.id))
      .then(() => (this.props.dispatch(getEventDetails(id))));
  }

  handleCreateEntry(selectedEntry) {
    // alert('Create Entry:' + selectedEntry.riderNumber);
    // dispatch...
    const insertEntry = selectedEntry;
    const { id } = this.props.EventDetails;
    insertEntry.eventId = id;

    this.props.dispatch(postEntry(insertEntry))
      .then(() => (this.props.dispatch(getEventDetails(id))));
  }

  handleUpdateEntry(selectedEntry) {
    // alert('Update Entry:' + selectedEntry.riderNumber);
    // dispatch...
    const { id } = this.props.EventDetails;

    this.props.dispatch(putEntry(selectedEntry))
      .then(() => (this.props.dispatch(getEventDetails(id))));
  }

  handleDeleteEntry(selectedEntry) {
    // alert('Delete Entry:' + selectedEntry.riderNumber);
    // dispatch...
    const { id } = this.props.EventDetails;

    this.props.dispatch(deleteEntry(selectedEntry.id))
      .then(() => (this.props.dispatch(getEventDetails(id))));
  }

  handleViewEventClick() {
    this.setState({ formMode: 'viewEventInDetails' });
  }

  handleViewScoresClick() {
    this.setState({ formMode: 'viewScores' });

  }
  handleViewSectionsClick() {
    this.setState({ formMode: 'viewSections', needsRefresh: true });
  }

  handleViewEntriesClick() {
    this.setState({ formMode: 'viewEntries', needsRefresh: true  });
  }

  render() {
    let activeComponent;
    // const {EventId} = this.props;
    const { formMode } = this.state;
    //const { ClubId, ClubEvents, error, loading} = this.props;


    // const { id, name, clubId, startDateTime, numberOfLaps, isPublicView } = this.props.EventDetails;

    const { EventDetails } = this.props;

    const { id, eventEntries, eventSections, eventClasses } = this.props.EventDetails;

    // loading error
    if (typeof id !== 'undefined') {
      switch (formMode) {
        case 'viewEventInDetails':
          activeComponent = (
            <React.Fragment>
              <EventInDetails
                EventDetails={EventDetails}
                formMode={formMode}
                onSubmitEventClick={this.onSubmitEventClick}
                onCancelEventClick={this.onCancelEventClick}
              />
            </React.Fragment>
          );
          break;
        case 'viewSections':
          activeComponent = (
            <SectionsList
              // EventId={this.state.selectedEventID}
              formMode={formMode}
              eventSections={eventSections}
              onCreateSection={this.onCreateSection}
              onUpdateSection={this.onUpdateSection}
              onDeleteSection={this.onDeleteSection}
            />
          );
          this.disableEventCommands = false;
          break;
        case 'viewEntries':
          activeComponent = (
            <EntriesList
              // EventId={this.state.selectedEventID}
              formMode={formMode}
              eventEntries={eventEntries}
              eventClasses={eventClasses}
              onCreateEntry={this.onCreateEntry}
              onUpdateEntry={this.onUpdateEntry}
              onDeleteEntry={this.onDeleteEntry}
            />
          );
          this.disableEventCommands = false;
          break;


        case 'viewScores':
          activeComponent = (
            <EventScores />
          );
          this.disableEventCommands = false;
          break;
        default:
          activeComponent = 'UNKNOWN....';
      }
    } else {
      activeComponent = 'LOADING....';
    }


    return (
      <div className="">
        <CommandBar
          items={[
            {
              key: 'viewEvent',
              name: 'Event',
              disabled: this.disableEventCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'View',
              },
              ariaLabel: 'Event',
              onClick: () => this.onViewEventClick(),
            },
            {
              key: 'viewEventSections',
              name: 'Sections',
              disabled: this.disableEventCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'View',
              },
              ariaLabel: 'viewSections',
              onClick: () => this.onViewSectionsClick(),
            },
            {
              key: 'viewEventEntries',
              name: 'Entries',
              disabled: this.disableEventCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'View',
              },
              ariaLabel: 'Entries',
              onClick: () => this.onViewEntriesClick(),
            },
            {
              key: 'viewScores',
              name: 'Scores',
              disabled: this.disableEventCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'View',
              },
              ariaLabel: 'Scores',
              onClick: () => this.onViewScoresClick(), // this.onViewScoresClick(),
            },
          ]}
        />
        {activeComponent}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // EventId: state.ClubAdminReducer.EventId,
  EventDetails: state.ClubAdminReducer.EventDetails,
  loading: state.ClubAdminReducer.loading,
  error: state.ClubAdminReducer.error,
});

export default connect(mapStateToProps)(EventDetails);
