import {
  POST_ENTRY_BEGIN,
  POST_ENTRY_SUCCESS,
  POST_ENTRY_FAILURE,
  PUT_ENTRY_BEGIN,
  PUT_ENTRY_SUCCESS,
  PUT_ENTRY_FAILURE,
  DELETE_ENTRY_BEGIN,
  DELETE_ENTRY_SUCCESS,
  DELETE_ENTRY_FAILURE,

} from './ClubAdminActionTypes';

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function postEntryBegin(NewEntryName) {
  return {
    type: POST_ENTRY_BEGIN,
    payload: { NewEntryName },
  };
}

export function postEntrySuccess(entryId) {
  return {
    type: POST_ENTRY_SUCCESS,
    payload: { entryId },
  };
}

export function postEntryFailure(error) {
  return {
    type: POST_ENTRY_FAILURE,
    payload: { error },
  };
}

export function postEntry(NewEntryName) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ ...NewEntryName }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(postEntryBegin(NewEntryName));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Entries`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(postEntrySuccess(json));
        return json;
      })
      .catch(error => dispatch(postEntryFailure(error)));
  };
}

export function putEntryBegin(updateEntry) {
  return {
    type: PUT_ENTRY_BEGIN,
    payload: { updateEntry },
  };
}

export function putEntrySuccess(updateEntry) {
  return {
    type: PUT_ENTRY_SUCCESS,
    payload: { updateEntry },
  };
}

export function putEntryFailure(error) {
  return {
    type: PUT_ENTRY_FAILURE,
    payload: { error },
  };
}

export function putEntry(updateEntry) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      body: JSON.stringify({ ...updateEntry }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(putEntryBegin(updateEntry));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Entries`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(putEntrySuccess(json));
        return json;
      })
      .catch(error => dispatch(putEntryFailure(error)));
  };
}

export function deleteEntryBegin(entryId) {
  return {
    type: DELETE_ENTRY_BEGIN,
    payload: { entryId },
  };
}

export function deleteEntrySuccess(entryId) {
  return {
    type: DELETE_ENTRY_SUCCESS,
    payload: { entryId },
  };
}

export function deleteEntryFailure(error) {
  return {
    type: DELETE_ENTRY_FAILURE,
    payload: { error },
  };
}

export function deleteEntry(entryId) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      body: JSON.stringify({ id: entryId }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(deleteEntryBegin(entryId));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Entries/${entryId}`;
    return fetch(url, options)
      .then(handleErrors)
      // .then(response => response.json())
      .then((json) => {
        dispatch(deleteEntrySuccess(json));
        return json;
      })
      .catch(error => dispatch(deleteEntryFailure(error)));
  };
}
