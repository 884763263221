import React from 'react';
import { Text } from 'office-ui-fabric-react/lib/Text';
// import { IFontStyles } from 'office-ui-fabric-react/lib/Styling';
// import { SessionInfo } from './components/SessionInfo/SessionInfo';


function Home() {
  return (

    <div>
      <h2>HOME</h2>
      <Text variant={'large'}>
        Welcome to the home of <b>ObservedTrials.Live</b>
        <br />
        <br />
        We are currently undertaking beta testing and will shortly be releasing an Observed Trials Events Solution.
        <br />
        <br />
        The features include:
        <ul>
          <li>Administration of club riders, events, classes, entries and sections through a web portal</li>
          <li>Real-time capture of scores by section Observers using mobile smartphone and tablet applications for Android and Apple</li>
          <li>Live publishing and broadcast of rider scores during an event</li>
          <li>Collating and distribution of event results</li>
          <li>A comprehensive plug-in interface to allow entries, live scores and results to be seamlessly displayed on existing club websites</li>
        </ul>
          Please check-back soon for updates on progress...
      </Text>

    </div>
  );
}

export default Home;
