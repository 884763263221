import {combineReducers} from 'redux';
import ClubDetailsReducer from './ClubDetails/ClubDetailsReducer';
import EventDetailsReducer from './EventDetails/EventDetailsReducer';
import SystemAdminReducer from './SystemAdmin/SystemAdminReducer';
import ClubAdminReducer from './ClubAdmin/ClubAdminReducer';
import SessionInfoReducer from './SessionInfo/SessionInfoReducer';

// import rootReducer from "../reducers/index";

const rootReducer = combineReducers({
  SystemAdminReducer,
  EventDetailsReducer,
  ClubDetailsReducer,
  ClubAdminReducer,
  SessionInfoReducer,
});

export default rootReducer;
