/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  Route,
  HashRouter,
} from 'react-router-dom';

import Home from './Home';
// import About from './About';
// import Contact from './Contact';
import ClubAdmin from './ClubAdmin';
import Event from './Event';
import Section from './Section';
import Admin from './Admin';
import HeaderBar from './components/HeaderBar';
import MainMenu from './components/MainMenu';

class Main extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div>
        <div>
          <HeaderBar />
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm2">
            <MainMenu />
          </div>
          <div className="ms-Grid-col ms-sm10">
            <HashRouter>
              <div>
                <div className="content">
                  <Route exact path="/" component={Home}/>
                  {/*<Route path="/about" component={About}/>*/}
                  <Route path="/clubadmin" component={ClubAdmin}/>
                  { /*
                  <Route path="/event" component={Event}/>
                  <Route path="/section" component={Section}/>
                  <Route path="/contact" component={Contact}/>
                  */ }
                  <Route path="/admin" component={Admin}/>
                </div>
              </div>
            </HashRouter>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
