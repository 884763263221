import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DetailsList, DetailsListLayoutMode, CheckboxVisibility, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';

// import { Separator } from 'office-ui-fabric-react/lib/Separator';
// import { DefaultButton, Label } from 'office-ui-fabric-react';
// import { ISelection, Selection, SelectionMode, SelectionZone } from 'office-ui-fabric-react/lib/Selection';
// import { Callout } from 'office-ui-fabric-react/lib/Callout';

import { getClubs, setPageMode } from '../redux/SystemAdmin/SystemAdminActions';
// import Club from '../components/Club';


// eslint-disable-next-line react/prefer-stateless-function
class ClubsList extends Component {
  constructor(props) {
    super(props);
    // this.state = { disableEdit: true, disableDelete: true };
    /*
    this._selection = new Selection({
      onSelectionChanged: () => this.setState({ selectionDetails: this._getSelectionDetails() })
    });
*/
    this.handleActiveItemChanged = this.handleActiveItemChanged.bind(this);

  }

  componentDidMount() {
    this.props.dispatch(getClubs());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.NewClubAdded === 1) {
      this.props.dispatch(getClubs());
    }
  }

  handleActiveItemChanged(club) {
    //alert('handleActiveItemChanged' + club.id)
    this.props.dispatch(setPageMode({ pageviewMode: 'ViewClubs', ClubId: club.id, ClubName: club.name, disableEdit: false, disableDelete: false,
    }));
  }



  render() {
    const { error, loading, clubs } = this.props;
    // const { pageviewMode, ClubId, ClubName, disableEdit, disableDelete} = this.props.pagemode;

    if (error) {
      return <div>Error! error</div>;
    }
    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="">


        <DetailsList
          checkboxVisibility={CheckboxVisibility.hidden}
          selectionMode={SelectionMode.single}
          // setKey="id"
          onActiveItemChanged={this.handleActiveItemChanged}
          items={clubs}
          // OnRenderDetailsHeader
          columns={[
            { key: 'column1', name: 'ClubId', fieldName: 'id', maxWidth: 50 },
            { key: 'column2', name: 'ClubName', fieldName: 'name',      minWidth: 50, maxWidth: 250, isResizable: true,
            },
          ]}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          isResizable
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // eventSections: state.EventDetailsReducer.items.eventSections
  clubs: state.SystemAdminReducer.clubs,
  loading: state.SystemAdminReducer.loading,
  error: state.SystemAdminReducer.error,
  NewClubAdded: state.SystemAdminReducer.NewClubAdded,
  pagemode: state.SystemAdminReducer.pagemode,
});


export default connect(mapStateToProps)(ClubsList);
