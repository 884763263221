import React, { Component } from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import {
  DetailsList,
  DetailsListLayoutMode,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';

import Class from './Class';


// eslint-disable-next-line react/prefer-stateless-function
class Classes extends Component {
  constructor(props) {
    super(props);
    const { ClubId, formMode } = this.props;
    this.state = {
      classesData: [],
      ClubId,
      formMode,
      selectedClass: {
        id: null,
        clubId: ClubId,
        className: null,
      },
    };

    this.disableClassCommands = true;

    this.onCancelClassClick = this.handleCancelClassClick.bind(this);
    this.onSubmitClassClick = this.handleSubmitClassClick.bind(this);
    this.onActiveItemChanged = this.handleActiveItemChanged.bind(this);
  }

  componentDidMount() {
    const { ClubId } = this.state;
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Clubs/${ClubId}/Classes`;
    return fetch(url)
      // .then(this.handleErrors)
      .then(response => response.json())
      .then((data) => {
        this.setState({ classesData: data });
      });
  }

  handleActiveItemChanged(selectedClass) {
    this.disableClassCommands = false;
    this.setState({ selectedClass });

    // this.props.onActiveItemChanged (selectedClass);

    this.props.onClubClassSelected(selectedClass);

    // this.props.onClubClassSelected
  }

  handleCancelClassClick() {
    this.disableClassCommands = true;
    this.setState(
      {
        selectedClass: {
          id: null,
          clubId: this.ClubId,
          className: null,
        },
        formMode: 'viewClasses',
      },
    );
  }

  handleEditClassClick() {
    this.setState({ formMode: 'editClass' });
  }

  // Y
  handleViewClassDetailsClick() {
    // const { id } = this.state.selectedClass
    this.setState({ formMode: 'viewClass' });
  }

  // Y
  handleNewClassClick(event) {
    // this.setState({ formMode: 'newEvent' });
    this.setState(
      {
        selectedClass: {
          id: null,
          clubId: this.ClubId,
          className: null,
        },
        formMode: 'newClass',
      },
    );
  }


  handleSubmitClassClick(formMode, ClassDetails) {
    // alert('UpdateEvent in EventDetails');
    const { ClubId, } = this.props;
    let url = '';
    let options = '';

    //need to handle fetch methods for 'editClass' and 'deleteClass'
    switch (formMode) {
      case 'newClass':
        options = {
          method: 'POST',
          body: JSON.stringify({
            id: 0,
            className: ClassDetails.className,
            clubId: ClubId,
          }),
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        };


        url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Clubs/${ClubId}/Classes`;
        return fetch(url, options)
          .then((data) => {
            url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Clubs/${ClubId}/Classes`;
            fetch(url)
              .then(response => response.json())
              .then((data1) => {
                this.setState({ classesData: data1 });
              });

            this.setState({
              classesData: data,
              formMode: 'viewClasses',
            });
          });

        break;
      default:
        // unknown formMode
    }
    this.setState({ formMode: 'viewClasses' });
  }

  // Y
  handleDeleteClassClick(event) {
    this.setState({ formMode: 'deleteClass' });
  }


  render() {
    const {
      classesData,
      ClubId,
      formMode,
      selectedClass,
    } = this.state;
    let activeComponent = '';
    // let disableEdit = true;
    // let disableDelete = true;

    switch (formMode) {
      case 'viewClasses':
        activeComponent = (
          <React.Fragment>
            <DetailsList
              checkboxVisibility={CheckboxVisibility.hidden}
              selectionMode={SelectionMode.single}
              selectionPreservedOnEmptyClick="true"
              onActiveItemChanged={this.onActiveItemChanged}
              setKey="Classes"
              // onActiveItemChanged={this.onSelectedEntryChanged}
              items={classesData}
              columns={[
                { key: 'column1', name: 'Id', fieldName: 'id', minWidth: 15, maxWidth: 30 },
                { key: 'column2', name: 'ClassName', fieldName: 'className', minWidth: 120, maxWidth: 120, isResizable: true  },
                { key: 'column3', name: 'ClubId', fieldName: 'clubId', minWidth: 30, maxWidth: 30, isResizable: true  },
              ]}
              layoutMode={DetailsListLayoutMode.justified}
            />
          </React.Fragment>
        );
        // activeComponent = '';
        // this.disableEventCommands = true;
        break;
      case 'newClass': // newEventInDetails
        activeComponent = (
          <React.Fragment>
            { /* <h3>New Event...: {id}</h3> */ }
            <Class
              ClassDetails={selectedClass}
              formMode={formMode}
              onSubmitClassClick={this.onSubmitClassClick}
              onCancelClassClick={this.onCancelClassClick}
            />
          </React.Fragment>
        );
        // disableEdit = true;
        // disableDelete = true;
        // this.disableEventCommands = false;
        break;
      case 'viewClass':
        activeComponent = (
          <React.Fragment>
            { /* <h3>New Event: {id}</h3> */ }
            <Class
              ClassDetails={selectedClass}
              formMode={formMode}
              onSubmitClassClick={this.onSubmitClassClick}
              onCancelClassClick={this.onCancelClassClick}
            />
          </React.Fragment>
        );
        // disableEdit = true;
        // disableDelete = true;
        this.disableClassCommands = true;
        break;
      case 'deleteClass':
        activeComponent = (
          <React.Fragment>
            {/*<h3>Delete Event: {id}</h3>*/}
            <Class
              ClassDetails={selectedClass}
              formMode={formMode}
              onSubmitClassClick={this.onSubmitClassClick}
              onCancelClassClick={this.onCancelClassClick}
            />
          </React.Fragment>
        );
        this.disableClassCommands = false;
        break;
      default:
        activeComponent = 'UNKNOWN....';
    }
    return (
      <div className="">
        <CommandBar
          items={[
            {
              key: 'Classes',
              text: 'CLASSES',
            },
            {
              key: 'newClass',
              name: 'New Class',
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'Add',
              },
              ariaLabel: 'New',
              onClick: () => this.handleNewClassClick(),
            },
            {
              key: 'viewClassDetails',
              name: 'Class Details',
              disabled: this.disableClassCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'View',
              },
              ariaLabel: 'viewClassDetails',
              onClick: () => this.handleViewClassDetailsClick(),
            },
            {
              key: 'deleteClass',
              name: 'Delete Class',
              disabled: this.disableClassCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'Delete',
              },
              ariaLabel: 'Delete',
              onClick: () => this.handleDeleteClassClick(),
            },
          ]
    }

        />


        {activeComponent}

      </div>
    );
  }
}

export default (Classes);
