import {
  GET_CLUBEVENTS_BEGIN,
  GET_CLUBEVENTS_SUCCESS,
  GET_CLUBEVENTS_FAILURE,
  GET_EVENTDETAILS_BEGIN,
  GET_EVENTDETAILS_SUCCESS,
  GET_EVENTDETAILS_FAILURE,

} from './ClubAdminActionTypes';

// eslint-disable-next-line import/prefer-default-export

export function getClubEventsBegin(ClubId) {
  return {
    type: GET_CLUBEVENTS_BEGIN,
  };
}

export function getClubEventsSuccess(ClubEvents) {
  return {
    type: GET_CLUBEVENTS_SUCCESS,
    payload: { ClubEvents },
  };
}

export function getClubEventsFailure(error) {
  return {
    type: GET_CLUBEVENTS_FAILURE,
    payload: { error },
  };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function getClubEvents(ClubId) {
  return (dispatch) => {
    dispatch(getClubEventsBegin(ClubId));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Clubs/${ClubId}`;
    return fetch(url)
      .then(handleErrors)
      .then(response => response.json())
      .then((json) => {
        dispatch(getClubEventsSuccess(json));
        return json;
      })
      .catch(error => dispatch(getClubEventsFailure(error)));
  };
}


export function getEventDetailsBegin(EventId) {
  return {
    type: GET_EVENTDETAILS_BEGIN,
  };
}

export function getEventDetailsSuccess(EventDetails) {
  return {
    type: GET_EVENTDETAILS_SUCCESS,
    payload: { EventDetails },
  };
}

export function getEventDetailsFailure(error) {
  return {
    type: GET_EVENTDETAILS_FAILURE,
    payload: { error },
  };
}

export function getEventDetails(EventId) {
  return (dispatch) => {
    dispatch(getEventDetailsBegin(EventId));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Events/${EventId}`;
    return fetch(url)
      .then(handleErrors)
      .then(response => response.json())
      .then((json) => {
        dispatch(getEventDetailsSuccess(json));
        return json;
      })
      .catch(error => dispatch(getEventDetailsFailure(error)));
  };
}
