import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DetailsList,
  DetailsListLayoutMode,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';

import { getClubEvents } from '../../redux/ClubAdmin/ClubAdminActions';
import { postEvent, putEvent, deleteEvent } from '../../redux/ClubAdmin/ClubAdmin_EventActions';

import EventInDetails from '../EventDetails/EventInDetails';

// eslint-disable-next-line react/prefer-stateless-function
class EventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formMode: 'viewEvents',
      clubId: this.props.sessionInfo.clubId,
      events: [],
      selectedEvent: { id: '', name: '' },
    };

    const ClubId  = this.props.sessionInfo.clubId;
    this.disableEventCommands = true;

    // console.log(this.props.onClubEventSelected);
    // this.onActiveItemChanged = this.handleActiveItemChanged.bind(this);

    this.onActiveItemChanged = this.handleActiveItemChanged.bind(this);

    // this.onClubEventSelected = this.props.onClubEventSelected.bind(this);

    this.onNewEventSubmit = this.handleNewEventSubmit.bind(this);
    this.onNewEventCancel = this.handleNewEventCancel.bind(this);

    this.onEditEventSubmit = this.handleEditEventSubmit.bind(this);
    this.onEditEventCancel = this.handleEditEventCancel.bind(this);

    this.onCancelEventClick = this.handleCancelEventClick.bind(this);
    this.onSubmitEventClick = this.handleSubmitEventClick.bind(this);

    // this.onViewEventDetailsClick = this.handleViewEventDetailsClick.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getClubEvents(this.props.sessionInfo.clubId));
  }

  componentWillReceiveProps(nextProps) {
    // this.props.dispatch(getClubEvents(12004));
  }

  handleActiveItemChanged(selectedEvent) {
    // this.setState({ selectedEventID: selectedEvent.id });
    // this.props.onClubEventSelected(selectedEvent);

    this.disableEventCommands = false;
    this.setState({ selectedEvent });

    // const { id, name } = selectedEvent;
    this.props.onClubEventSelected(selectedEvent);
  }

  handleNewEventSubmit (event) {
    alert('handleNewEventSubmit:' + event.id);
    // fetch PUT verb here
    //dispatch putEvent()
    this.setState({ formMode: 'viewEvents' });
  }

  handleNewEventCancel (event) {
    alert('handleNewEventCancel:' + event);
    this.setState({ formMode: 'viewEvents' });
  }

  handleEditEventSubmit (event) {
    alert('handleEditEventSubmit:' + event.id);
    this.setState({ formMode: 'viewEvents' });
  }

  handleEditEventCancel (event) {
    alert('handleEditEventCancel:' + event);
    this.setState({ formMode: 'viewEvents' });

  }

  handleViewEventDetailsClick () {
    const { id, name } = this.state.selectedEvent
    this.props.onViewEventDetailsClick(id, name);
  }

  handleNewEventClick(event) {
    // this.setState({ formMode: 'newEvent' });
    let newDate = new Date();
    this.setState(
      {
        selectedEvent: {
          id: null,
          clubId: this.state.clubId,
          name: 'new event',
          startDateTime: newDate,
          isPublicView: false,
        },
        formMode: 'newEvent',
      },
    );
  }
  handleEditEventClick(event) {
    this.setState({ formMode: 'editEvent' });

  }

  handleDeleteEventClick(event) {
    this.setState({ formMode: 'deleteEvent' });
  }

  handleCancelEventClick() {
    // this.setState({ formMode: 'viewSections' });
    this.disableEventCommands = true;
    this.setState(
      {
        selectedEvent: {
          id: 0,
        },
        formMode: 'viewEvents',
      },
    );
  }

  handleSubmitEventClick(formMode, EventDetails) {
    // alert('UpdateEvent in EventDetails');
    const { id } = EventDetails;
    const clubId = this.props.sessionInfo.clubId; // Club ID
    const submitEvent = EventDetails;

    submitEvent.clubId = clubId;

    switch (formMode) {
      case 'newEvent':
      case 'newEventInDetails':
        submitEvent.id = 0;
        this.props.dispatch(postEvent(EventDetails))
          .then(() => (this.props.dispatch(getClubEvents(clubId))));
        break;
      case 'editEvent':
      case 'editEventInDetails':
        this.props.dispatch(putEvent(EventDetails))
          .then(() => (this.props.dispatch(getClubEvents(clubId))));
        break;
      case 'deleteEvent':
      case 'deleteEventInDetails':
        this.props.dispatch(deleteEvent(id))
          .then(() => (this.props.dispatch(getClubEvents(clubId))));
        this.disableEventCommands = true;
        break;
      default:
        // unknown formMode
    }
    this.setState({ formMode: 'viewEvents' });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    // const { Id, ClubEvents, error, loading} = this.props;
    let activeComponent = '';
    const { formMode, selectedEvent } = this.state;
    const { events } = this.props.ClubEvents;

    const EventsDetailsList = (
      <DetailsList
        checkboxVisibility={CheckboxVisibility.hidden}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick="true"
        onActiveItemChanged={this.onActiveItemChanged}
        setKey="SectionNo"
        items={events}
        columns={[
          { key: 'column1', name: 'id', fieldName: 'id' },
          { key: 'column2', name: 'name', fieldName: 'name' },
          { key: 'column3', name: 'startDateTime', fieldName: 'startDateTime' },
          // { key: 'column4', name: 'clubId', fieldName: 'clubId' },
        ]}
        layoutMode={DetailsListLayoutMode.fixedColumns}
      />
    );

    switch (formMode) {
      case 'viewEvents':
        activeComponent = (
          <React.Fragment>
            { /* <h3>
              View Events:
              {id}
            </h3> */ }
            {EventsDetailsList}
          </React.Fragment>
        );
        // this.disableEventCommands = true;
        break;
      case 'viewEvent': // newEventInDetails
        activeComponent = (
          <React.Fragment>
            { /* <h3>New Event...: {id}</h3> */ }
            <EventInDetails
              EventDetails={selectedEvent}
              formMode={formMode}
              onSubmitEventClick={this.onSubmitEventClick}
              onCancelEventClick={this.onCancelEventClick}
            />
          </React.Fragment>
        );
        // this.disableEventCommands = false;
        break;
      case 'newEvent':
        activeComponent = (
          <React.Fragment>
            { /* <h3>New Event: {id}</h3> */ }
            <EventInDetails
              EventDetails={selectedEvent}
              formMode={formMode}
              onSubmitEventClick={this.onSubmitEventClick}
              onCancelEventClick={this.onCancelEventClick}
            />
          </React.Fragment>
        );
        this.disableEventCommands = true;
        break;
      case 'editEvent':
        activeComponent = (
          <React.Fragment>
            {/*<h3>Edit Event: {id}</h3>*/}
            <EventInDetails
              EventDetails={selectedEvent}
              formMode={formMode}
              onSubmitEventClick={this.onSubmitEventClick}
              onCancelEventClick={this.onCancelEventClick}
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      case 'deleteEvent':
        activeComponent = (
          <React.Fragment>
            {/*<h3>Delete Event: {id}</h3>*/}
            <EventInDetails
              EventDetails={selectedEvent}
              formMode={formMode}
              onSubmitEventClick={this.onSubmitEventClick}
              onCancelEventClick={this.onCancelEventClick}
            />
          </React.Fragment>
        );
        this.disableEventCommands = false;
        break;
      default:
        activeComponent = 'UNKNOWN....';
    }


    return (
      <React.Fragment>
        {/*}h3>
          {selectedEvent.name}
    </h3>*/}
        <CommandBar
          items={[
            {
              key: 'newEvent',
              name: 'New Event',
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'Add',
              },
              ariaLabel: 'New',
              onClick: () => this.handleNewEventClick(),
            },
            /* {
              key: 'editEvent',
              name: 'Edit',
              disabled: this.disableEventCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'Edit',
              },
              ariaLabel: 'Edit',
              onClick: () => this.handleEditEventClick(),
            }, */
            {
              key: 'viewEventDetails',
              name: 'Event Details',
              disabled: this.disableEventCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'View',
              },
              ariaLabel: 'viewEventDetails',
              onClick: () => this.handleViewEventDetailsClick(),
            },
            {
              key: 'deleteEvent',
              name: 'Delete Event',
              disabled: this.disableEventCommands,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'Delete',
              },
              ariaLabel: 'Delete',
              onClick: () => this.handleDeleteEventClick(),
            },
          ]
          }
        />


      <div className="">
        {/*<h3>
          Form Mode: {formMode}
        </h3> */}

        {activeComponent}
      </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ClubId: state.ClubAdminReducer.ClubId,
  ClubEvents: state.ClubAdminReducer.ClubEvents,
  loading: state.ClubAdminReducer.loading,
  error: state.ClubAdminReducer.error,
  sessionInfo: state.SessionInfoReducer.sessionInfo,
});


export default connect(mapStateToProps)(EventsList);
