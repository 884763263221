import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DefaultButton } from 'office-ui-fabric-react';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { Dropdown, DropdownMenuItemType, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';


import { postUser, putUser, setPageMode } from '../../redux/SystemAdmin/SystemAdminActions';

const DayPickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],

  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],

  shortDays: [
    'S',
    'M',
    'T',
    'W',
    'T',
    'F',
    'S'
  ],

  goToToday: 'Go to today'
};

class Event extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Event: this.props.Event
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // const {Event} = this.props;
  }

  handleCancel() {
  }

  handleChange(event) {
    if (event.target.id === 'NewUserName') {
      this.setState({ NewUserName: event.target.value });
      alert('Form Changed: ' + this.props.EventName)
    }
  }

  handleCheckboxChange(event, isPublicView) {
    // alert('CheckboxChanged: ' + isChecked)
    this.setState(state => ({
      Event: {
        ...state.Event,
        isPublicView,
      },
    }));
  }

  handleSubmit(e) {
    e.preventDefault();
    //this.props.dispatch(putEvent(this.state.Event));
    this.props.onSubmit(this.state.Event);

    if (this.props.formMode === 'editEvent') {
      // this.props.dispatch(putEvent(this.state.Event));
    }

    if (this.props.formMode === 'newEvent') {
      // this.props.dispatch(postEvent(this.state.Event));
    }
  }

  render() {
    const columnProps = {
      tokens: { childrenGap: 5 },
      styles: { root: { width: 250 } }
    };

    const { selectedItem } = { key: 'grape', text: 'Grape' };
    const { id, clubId, name, startDateTime, isPublicView } = this.state.Event;

    const startDate = new Date(startDateTime);


    let userNameText = '';

    return (
      <div className="">
        { /*<h3>
          EventId: { id }
          formMode: { this.props.formMode }
        </h3> */ }
        <form
          className="eventForm"
          id="eventForm"
          onSubmit={this.handleSubmit}
          //onSubmit={this.props.onSubmit}
        >
          <Stack
            horizontal tokens={{ childrenGap: 50 }}
            styles={{ root: { width: 450 } }}
          >
            <Stack {...columnProps}>
              {/* label="User Name" */}
              <TextField
                id="EventId"
                label="EventId"
                value={id}
                onChange={this.handleChange}
                disabled="true"
              />
              <TextField
                id="ClubId"
                label="ClubId"
                value={this.state.Event.clubId}
                onChange={this.handleChange}
                disabled="true"
              />
              <TextField
                id="EventName"
                label="EventName"
                value={this.state.Event.name}
                onChange={this.handleChange} />
              <DatePicker
                id="StartDate"
                label="StartDate"
                value={startDate}
                onChange={this.handleChange}
                isRequired={false}
                placeholder="Select a date..."
                ariaLabel="Select a date"
              />

              <Checkbox
                id="isPublicView"
                label="IsPublicView"
                checked={this.state.event.isPublicView}
                onChange={this.handleCheckboxChange}
                defaultValue={this.state.event.isPublicView}
              />

            </Stack>
            <Stack {...columnProps}>
              {/* <Label>Click Create to complete</Label> */}
              <DefaultButton
                type="submit"
                data-automation-id="test"
                text="Submit"
              />
              <DefaultButton
                // type=""
                // onClick="Cancel"
                onClick={this.props.onCancel}
                text="Cancel"
              />
            </Stack>
          </Stack>
        </form>
      </div>
    );
  }
}

export default (Event);
