import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DetailsList, DetailsListLayoutMode, CheckboxVisibility, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { getUsers, setPageMode } from '../../redux/SystemAdmin/SystemAdminActions';

// eslint-disable-next-line react/prefer-stateless-function
class UsersList extends Component {
  constructor(props) {
    super(props);
    this.handleActiveItemChanged = this.handleActiveItemChanged.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getUsers());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.NewUserAdded === 1) {
      this.props.dispatch(getUsers());
    }
  }

  handleActiveItemChanged(selectedUser) {
    this.props.dispatch(setPageMode({
      selectedUser,
      pageviewMode: 'ViewUsers',
      UserId: selectedUser.id,
      UserName: selectedUser.name,
      disableEdit: false,
      disableDelete: false,
    }));

  }

  render() {
    const { error, loading, users } = this.props;
    // const { pageviewMode, UserId, UserName, disableEdit, disableDelete} = this.props.pagemode;

    if (error) {
      return <div>Error! error</div>;
    }
    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="">


        <DetailsList
          checkboxVisibility={CheckboxVisibility.hidden}
          selectionMode={SelectionMode.single}
          // setKey="id"
          onActiveItemChanged={this.handleActiveItemChanged}
          items={users}
          // OnRenderDetailsHeader
          columns={[
            { key: 'column1', name: 'UserId', fieldName: 'id', maxWidth: 50 },
            { key: 'column2', name: 'UserName', fieldName: 'userName', minWidth: 50, maxWidth: 250, isResizable: true,
            },
          ]}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          isResizable
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // eventSections: state.EventDetailsReducer.items.eventSections
  users: state.SystemAdminReducer.users,
  loading: state.SystemAdminReducer.loading,
  error: state.SystemAdminReducer.error,
  NewUserAdded: state.SystemAdminReducer.NewUserAdded,
  pagemode: state.SystemAdminReducer.pagemode,
});


export default connect(mapStateToProps)(UsersList);
