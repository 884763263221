import {
  FETCH_EVENTDETAILS_BEGIN,
  FETCH_EVENTDETAILS_SUCCESS,
  FETCH_EVENTDETAILS_FAILURE,
} from './EventDetailsActionTypes';

const initialState = {
  items:
    {
      eventSections: [],
      eventEntries: [],
    },
  loading: false,
  error: null,
};


function EventDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTDETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_EVENTDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.eventDetails,
      };
/* items: action.payload.eventDetails.eventSections */
    case FETCH_EVENTDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}

export default EventDetailsReducer;
