import {
  SET_PAGE_MODE,

  GET_CLUBEVENTS_BEGIN,
  GET_CLUBEVENTS_SUCCESS,
  GET_CLUBEVENTS_FAILURE,
  GET_EVENTDETAILS_BEGIN,
  GET_EVENTDETAILS_SUCCESS,
  GET_EVENTDETAILS_FAILURE,

  POST_ENTRY_BEGIN,
  POST_ENTRY_SUCCESS,
  POST_ENTRY_FAILURE,
  PUT_ENTRY_BEGIN,
  PUT_ENTRY_SUCCESS,
  PUT_ENTRY_FAILURE,
  DELETE_ENTRY_BEGIN,
  DELETE_ENTRY_SUCCESS,
  DELETE_ENTRY_FAILURE,

  POST_SECTION_BEGIN,
  POST_SECTION_SUCCESS,
  POST_SECTION_FAILURE,
  PUT_SECTION_BEGIN,
  PUT_SECTION_SUCCESS,
  PUT_SECTION_FAILURE,
  DELETE_SECTION_BEGIN,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE,

  POST_EVENT_BEGIN,
  POST_EVENT_SUCCESS,
  POST_EVENT_FAILURE,
  PUT_EVENT_BEGIN,
  PUT_EVENT_SUCCESS,
  PUT_EVENT_FAILURE,
  DELETE_EVENT_BEGIN,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,

} from './ClubAdminActionTypes';

const initialState = {
  ClubId: null,
  users: [],
  loading: false,
  error: null,
  ClubEvents: {
    events: [] },
  EventDetails: {
    eventSections: [],
    eventEntries: [],
    eventClasses: [],
  },
};

function ClubAdminReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLUBEVENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        ClubId: action.payload,
      };

    case GET_CLUBEVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ClubEvents: action.payload.ClubEvents,
      };
/* items: action.payload.eventDetails.eventSections */
    case GET_CLUBEVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ClubEvents: [],
      };

    case GET_EVENTDETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        EventId: action.payload,
      };

    case GET_EVENTDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        EventDetails: action.payload.EventDetails,
      };
/* items: action.payload.eventDetails.eventSections */
    case GET_EVENTDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        EventDetails: [],
      };
    // ////////////////  SECTION ////////////////// //
    case POST_SECTION_BEGIN:
      return {
        ...state,
      };
    case POST_SECTION_SUCCESS:
      return {
        ...state,
        NewSectionAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewSections', SectionId: null, SectionName: null, disableEdit: true, disableDelete: true,
          }
      };
    case POST_SECTION_FAILURE:
      return {
        ...state,
      };

    case SET_PAGE_MODE:
      return {
        ...state,
        pagemode: action.payload.pagemode,
      };

    case DELETE_SECTION_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SECTION_SUCCESS:
      return {
        ...state,
        NewSectionAdded: 1,
        loading: false,
        pagemode:
          {
            pageviewMode: 'ViewSections', SectionId: null, SectionName: null, disableEdit: true, disableDelete: true,
          }
      };

    case DELETE_SECTION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case PUT_SECTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PUT_SECTION_SUCCESS:
      return {
        ...state,
        loading: true,
        NewSectionAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewSections', SectionId: null, SectionName: null, disableEdit: true, disableDelete: true,
          },
      };
    case PUT_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    // ////////////////  ENTRY ////////////////// //
    case POST_ENTRY_BEGIN:
      return {
        ...state,
      };
    case POST_ENTRY_SUCCESS:
      return {
        ...state,
        NewEntryAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewEntries', EntryId: null, EntryName: null, disableEdit: true, disableDelete: true,
          }
      };
    case POST_ENTRY_FAILURE:
      return {
        ...state,
      };

    case DELETE_ENTRY_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_ENTRY_SUCCESS:
      return {
        ...state,
        NewEntryAdded: 1,
        loading: false,
        pagemode:
          {
            pageviewMode: 'ViewEntries', EntryId: null, EntryName: null, disableEdit: true, disableDelete: true,
          }
      };

    case DELETE_ENTRY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case PUT_ENTRY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PUT_ENTRY_SUCCESS:
      return {
        ...state,
        loading: true,
        NewEntryAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewEntries', EntryId: null, EntryName: null, disableEdit: true, disableDelete: true,
          },
      };
    case PUT_ENTRY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    // ////////////////  EVENT ////////////////// //
    case POST_EVENT_BEGIN:
      return {
        ...state,
      };
    case POST_EVENT_SUCCESS:
      return {
        ...state,
        NewEventAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewEvents', EventId: null, EventName: null, disableEdit: true, disableDelete: true,
          }
      };
    case POST_EVENT_FAILURE:
      return {
        ...state,
      };

    case DELETE_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        NewEventAdded: 1,
        loading: false,
        pagemode:
          {
            pageviewMode: 'ViewEvents', EventId: null, EventName: null, disableEdit: true, disableDelete: true,
          }
      };

    case DELETE_EVENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case PUT_EVENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PUT_EVENT_SUCCESS:
      return {
        ...state,
        loading: true,
        NewEventAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewEvents', EventId: null, EventName: null, disableEdit: true, disableDelete: true,
          },
      };
    case PUT_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export default ClubAdminReducer;
