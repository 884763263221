import React, { Component } from 'react';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DefaultButton } from 'office-ui-fabric-react';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { DatePicker } from 'office-ui-fabric-react/lib/DatePicker';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
// import { Toggle } from 'office-ui-fabric-react/lib/Toggle';

class EventInDetails extends Component {

  constructor(props) {
    super(props);
    this.state = props;
    // this.state = { formMode: props.formMode };


    this.onCancel = this.handleCancel.bind(this);
    this.onCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.onChangeNumberOfLaps = this.handleChangeNumberOfLaps.bind(this);
    this.onSubmit = this.handleSubmit.bind(this);
    this.onEditEventInDetailsClick = this.handleEditEventInDetailsClick.bind(this);
    this.onDeleteEventInDetailsClick = this.handleDeleteEventInDetailsClick.bind(this);
  }

  componentDidMount() {
    // const {Event} = this.props;
    // this.setState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(nextProps);
    this.state = { ...nextProps };
  }

  handleEditEventInDetailsClick() {
    this.setState({ formMode: 'editEventInDetails' });
  }

  handleDeleteEventInDetailsClick() {
    this.setState({ formMode: 'deleteEventInDetails' });
  }

  handleCancel() {
    this.props.onCancelEventClick();
    // this.setState({ formMode: 'viewEventInDetails' });
  }

  handleChange(event) {
    const { id } = event.target;

/*    if (id === 'EventName') {
      this.setState({ EventDetails: { name: event.target.value } });
    }
 */
    switch (id) {
      case 'name':
        this.setState(state => ({
          EventDetails: {
            ...state.EventDetails,
            name: event.target.value,
          },
        }));
        break;
      case 'numberOfLaps':
        this.setState(state => ({
          EventDetails: {
            ...state.EventDetails,
            numberOfLaps: event.target.value,
          },
        }));
        break;
      case 'isPublicView':
        const { checked } = event.target;

        this.setState(state => ({
          EventDetails: {
            ...state.EventDetails,
            isPublicView: checked,
          },
        }));
        break;

      default:
      // unhandled formfield...
    }
  }

  handleSelectDate(startDate) {
    this.setState(state => ({
      EventDetails: {
        ...state.EventDetails,
        startDateTime: startDate,
      },
    }));
  }

  handleChangeNumberOfLaps(numberOfLaps) {
    this.setState(state => ({
      EventDetails: {
        ...state.EventDetails,
        numberOfLaps,
      },
    }));
  }

  handleCheckboxChange(event, isPublicView) {
    // alert('CheckboxChanged: ' + isChecked)

    this.setState(state => ({
      EventDetails: {
        ...state.EventDetails,
        isPublicView,
      },
    }));
  }

  handleSubmit(e) {
    e.preventDefault();
    const { formMode } = this.state;
    const { EventDetails } = this.state;
    // const paramSection = { formMode, ...selectedSection };

    this.props.onSubmitEventClick(formMode, EventDetails);
    // this.setState({ formMode: 'viewEvents' });

  }

  render() {
    const {
      id,
      clubId,
      name,
      numberOfLaps,
      startDateTime,
      isPublicView,
    } = this.state.EventDetails; // props.EventDetails;

    const {formMode} = this.state;
    let isViewOnly = true;
    let isDisableSubmit = false;
    let isDisableCancel = false;
    const columnProps = {
      tokens: { childrenGap: 5 },
      styles: { root: { width: 250 } }
    };


    // const { selectedItem } = { key: 'grape', text: 'Grape' };


    const startDate = new Date(startDateTime);


    // let userNameText = '';

    let Menu = (
      <CommandBar
        items={[
          {
            key: 'EventInDetails',
            text: 'EVENT',
          },
          {
            key: 'editEvent',
            text: 'Edit Event',
            iconProps: { iconName: 'Edit' },
            onClick: () => this.onEditEventInDetailsClick(),
          },
          /* {
            disabled: 'true',
            key: 'deleteEvent',
            text: 'Delete',
            iconProps: { iconName: 'Delete' },
            onClick: () => this.onDeleteEventInDetailsClick(),
          }, */
        ]
      }
      />
    );

    switch (formMode) {
      case 'newEvent':
        Menu = '';
        isViewOnly = false;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      case 'viewEvent':
        Menu = '';
      // eslint-disable-next-line no-fallthrough
      case 'viewEventInDetails':
        isViewOnly = true;
        isDisableSubmit = true;
        isDisableCancel = true;
        break;
      case 'editEvent':
      case 'editEventInDetails':
        // Menu = '';
        isViewOnly = false;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      case 'deleteEvent':
        Menu = '';
      // eslint-disable-next-line no-fallthrough
      case 'deleteEventInDetails':
        isViewOnly = true;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      default:
        this.disableEventCommands = false;
    }

    return (
      <div className="">
        {Menu}
        { /* <h3>Form Mode: {formMode}</h3> */ }
        <form
          className="eventForm"
          id="eventForm"
          onSubmit={this.onSubmit}
          // onSubmit={this.props.onSubmit}
        >
          <Stack
            horizontal tokens={{ childrenGap: 50 }}
            styles={{ root: { width: 450 } }}
          >
            <Stack {...columnProps}>
              {/* label="User Name" */}

              <TextField
                id="name"
                label="EventName"
                value={name}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />
              <DatePicker
                id="startDateTime"
                label="StartDate"
                value={startDate}
                onSelectDate={this.handleSelectDate}
                isRequired={false}
                placeholder="Select a date..."
                ariaLabel="Select a date"
                disabled={isViewOnly}
              />

              <Slider
                id="numberOfLaps"
                label="NumberOfLaps"
                value={numberOfLaps}
                min={1}
                max={5}
                step={1}
                showValue={true}
                onChange={this.onChangeNumberOfLaps}
                disabled={isViewOnly}
              />

              <Checkbox
                id="isPublicView"
                label="IsPublicView"
                checked={isPublicView}
                onChange={this.onCheckboxChange}
                defaultValue={isPublicView}
                disabled={isViewOnly}
              />

              <TextField
                id="id"
                label="EventId"
                value={id}
                onChange={this.handleChange}
                disabled="true"
              />
              <TextField
                id="clubId"
                label="ClubId"
                value={clubId}
                onChange={this.handleChange}
                disabled="true"
              />

            </Stack>
            <Stack {...columnProps}>
              {/* <Label>Click Create to complete</Label> */}
              <DefaultButton
                type="submit"
                data-automation-id="test"
                text="Submit"
                disabled={isDisableSubmit}
              />
              <DefaultButton
                // type=""
                // onClick="Cancel"
                onClick={this.onCancel}
                text="Cancel"
                disabled={isDisableCancel}
              />

            </Stack>
          </Stack>
        </form>
      </div>
    );
  }
}

export default (EventInDetails);
