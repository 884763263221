// export const GET_EVENTDETAILS = 'GET_EVENTDETAILS';
// export const POST_EVENTDETAILS = 'POST_EVENTDETAILS';

// align naming to API methods...
// GET    read
// POST   create
// PUT    update-replace
// PATCH  update-modify - do not use....
// DELETE delete

export const FETCH_EVENTDETAILS_BEGIN = 'FETCH_EVENTDETAILS_BEGIN';
export const FETCH_EVENTDETAILS_SUCCESS = 'FETCH_EVENTDETAILS_SUCCCESS';
export const FETCH_EVENTDETAILS_FAILURE = 'FETCH_EVENTDETAILS_FAILURE';
