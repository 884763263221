/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Provider } from 'react-redux';
import Main from './Main';
import store from './redux/Store';

// import { addArticle } from './actions/index';
// import HeaderBar from './components/HeaderBar';
// import App from './components/App';


// import './index.css';


// window.store = store;
// window.addArticle = addArticle;

// We add Provider as Outermost component in our app
// so that every component has access to Redux Store

ReactDOM.render(
  <Provider store={store}>
    <Fabric className="App">
      <Main />
    </Fabric>
  </Provider>,
  document.getElementById('root'),
);
