import {
  USER_LOGON_BEGIN,
  USER_LOGON_SUCCESS,
  USER_LOGON_FAILURE,
  USER_LOGOFF_SUCCESS,
} from './SessionInfoActionTypes';

// eslint-disable-next-line import/prefer-default-export


// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function userLogonBegin() {
  return {
    type: USER_LOGON_BEGIN,
  };
}

export function userLogonSuccess(sessionInfo) {
  return {
    type: USER_LOGON_SUCCESS,
    payload: { sessionInfo },
  };
}

export function userLogoff(sessionInfo) {
  return {
    type: USER_LOGOFF_SUCCESS,
    payload: { sessionInfo },
  };
}

export function userLogonFailure(error) {
  return {
    type: USER_LOGON_FAILURE,
    payload: { error },
  };
}

export function userLogon(login) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      body: JSON.stringify({ ...login }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    };

    dispatch(userLogonBegin(login.userName));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Logon`;
    return fetch(url, options)
      .then(handleErrors)
      .then(response => response.json())
      .then((json) => {
        dispatch(userLogonSuccess(json));
        return json;
      })
      .catch(error => dispatch(userLogonFailure(error)));
  };
}
