import React, { Component } from 'react';
// import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Pivot, PivotItem, PivotLinkSize } from 'office-ui-fabric-react/lib/Pivot';
import { Breadcrumb } from 'office-ui-fabric-react/lib/Breadcrumb';

// import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import EventsList from './components/Events/EventsList';
import EventDetail from './components/EventDetails/EventDetails';
import Event from './components/EventDetails/Event';
import Classes from './components/Classes/Classes';

// import EventInDetails from './components/EventDetails/EventInDetails';

// These to be defined in session state after login
// const usersClubId = 12004;
// const usersClubName = 'Manx Youth Trials Club';

class ClubAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formMode: 'viewEvents',
      // clubId: usersClubId,
      selectedEvent:
        {
          id: null,
          clubName: null,
          clubId: this.props.sessionInfo.clubId,
        },
      selectedClass: {
        id: null,
        clubId: this.props.sessionInfo.clubId,
        className: null,
      },
      // ClubId: 12004,
      // events: [],
    };


    // this.onEventSelected = this.handleEventSelected.bind(this);
    this.disableEventCommands = true;

    this.onPivotItemClick = this.handlePivotItemClick.bind(this);

    // this.onNewEventSubmit = this.handleNewEventSubmit.bind(this);
    // this.onNewEventCancel = this.handleNewEventCancel.bind(this);

    // this.onEditEventSubmit = this.handleEditEventSubmit.bind(this);
    // this.onEditEventCancel = this.handleEditEventCancel.bind(this);

    this.onEventSelected = this.handleEventSelected.bind(this);

    this.onClassSelected = this.handleClassSelected.bind(this);

    this.onViewEventDetailsClick = this.handleViewEventDetailsClick.bind(this);
  }

  componentDidMount() {
    // this.props.dispatch(getClubEvents(12004));
  }

  handlePivotItemClick(item: PivotItem): void {
    if (item.props.headerText === 'Events') {
      this.setState({ formMode: 'viewEvents' });
    }
    if (item.props.headerText === 'Classes') {
      this.setState({ formMode: 'viewClasses' });
    }
    if (item.props.headerText === 'Riders') {
      this.setState({ formMode: 'viewRiders' });
    }
  }

  handleEventSelected(selectedEvent) {
    // alert('handleEventSelected Called!');
    this.setState(
      {
        formMode: 'viewEvents',
        selectedEvent,
        selectedClass:
        {
          name: null,
        },
      },
    );
    this.disableEventCommands = false;
  }

  handleClassSelected(selectedClass) {
    this.setState(
      {
        formMode: 'viewClasses',
        selectedEvent:
        {
          name: null,
        },
        selectedClass:
        {
          name: selectedClass.className,
        },
      },
    );
    this.disableEventCommands = false;
  }

  /*
  handleNewEventClick(event) {
    // this.setState({ formMode: 'newEvent' });
    let newDate = new Date();
    this.setState(
      {
        selectedEvent: {
          id: null,
          clubId: this.usersClubId,
          name: 'new event',
          startDateTime: newDate,
          isPublicView: false,
        },
        formMode: 'newEvent',
      },
    );
  }

  handleEditClick(event) {
    if (this.state.formMode === 'viewEvents') {
      this.setState({ formMode: 'editEvent' });
    }
  }

  handleNewEventSubmit(event) {
    alert('handleNewEventSubmit:' + event.id);
    // fetch PUT verb here
    //dispatch putEvent()
    this.setState({ formMode: 'viewEvents' });
  }

  handleNewEventCancel(event) {
    alert('handleNewEventCancel:' + event);
    this.setState({ formMode: 'viewEvents' });
  }

  handleEditEventSubmit(event) {
    alert('handleEditEventSubmit:' + event.id);
    this.setState({ formMode: 'viewEvents' });
  }

  handleEditEventCancel(event) {
    // alert('handleEditEventCancel:' + event);
    this.setState({ formMode: 'viewEvents' });
  }

  handleDeleteClick(event) {
    this.setState({ formMode: 'deleteEvent' });
  }
*/

  handleViewEventDetailsClick(eventId, eventName) {
    this.setState(
      {
        formMode: 'viewEventDetails',
        selectedEvent: {
          id: eventId,
          name: eventName,
        },
      },
    );
  }

  /*
  handleViewEventSections() {
    this.setState({ formMode: 'viewSections' });
  }

  handleViewEventEntries() {
    this.setState({ formMode: 'viewEntries' });
  }
*/

  render() {
    let activeComponent;
    let breadcrumbComponent;

    const { formMode, selectedEvent, selectedClass } = this.state;
    const {userName, clubId, clubName} = this.props.sessionInfo;

    if (userName!==null) {
      switch (formMode) {
        case 'viewEvents':
          activeComponent = (
            <React.Fragment>
              <EventsList
                ClubId={clubId}
                formMode={formMode}
                onClubEventSelected={this.onEventSelected}
                onViewEventDetailsClick={this.onViewEventDetailsClick}
                //  disableEdit = {false}
                // disableDelete = {true}
              />
            </React.Fragment>
          );
          breadcrumbComponent = (
            <Breadcrumb
              items={
              [
                { text: clubName },
                { text: 'Events' },
                { text: selectedEvent.name },
              ]
            }
              maxDisplayedItems={3}
            />
          );
          break;
        case 'viewClasses':
          activeComponent = (
            <React.Fragment>
              <Classes
                ClubId={clubId}
                formMode={formMode}
                onClubClassSelected={this.onClassSelected}
              />
            </React.Fragment>
          );
          breadcrumbComponent = (
            <Breadcrumb
              items={
              [
                { text: clubName },
                { text: 'Classes' },
                { text: selectedClass.name },
              ]
            }
              maxDisplayedItems={3}
            />
          );
          break;
        case 'viewRiders':
          activeComponent = (
            <h3>View Riders</h3>
          );
          breadcrumbComponent = (
            <Breadcrumb
              items={
              [
                { text: clubName },
                { text: 'Riders' },
              ]
            }
              maxDisplayedItems={3}
            />
          );
          break;
        case 'newEvent':
          activeComponent = (
            <Event
              Event={selectedEvent}
              formMode={formMode}
              onSubmit={this.onNewEventSubmit}
              onCancel={this.onNewEventCancel}
            />
          );
          breadcrumbComponent = (
            <Breadcrumb
              items={
              [
                { text: clubName },
                { text: 'Events' },
              ]
            }
              maxDisplayedItems={3}
            />
          );
          break;
        case 'editEvent':
          activeComponent = (
            <Event
              Event={selectedEvent}
              formMode={formMode}
              onSubmit={this.onEditEventSubmit}
              onCancel={this.onEditEventCancel}
            />
          );
          breadcrumbComponent = (
            <Breadcrumb
              items={
              [
                { text: clubName },
                { text: 'Events' },
                { text: selectedEvent.name },
              ]
            }
              maxDisplayedItems={3}
            />
          );
          break;

        case 'deleteEvent':
          activeComponent = (
            <Event
              EventId={selectedEvent}
              formMode={formMode}
            />
          );
          breadcrumbComponent = (
            <Breadcrumb
              items={
              [
                { text: clubName },
                { text: 'Events' },
                { text: selectedEvent.name },
              ]
            }
              maxDisplayedItems={3}
            />
          );
          break;
        case 'viewEventDetails':
          activeComponent = (
            <React.Fragment>
              <EventDetail EventId={selectedEvent.id} />
            </React.Fragment>
          );
          breadcrumbComponent = (
            <Breadcrumb
              items={
              [
                { text: clubName },
                { text: 'Events' },
                { text: selectedEvent.name },
              ]
            }
              maxDisplayedItems={3}
            />
          );
          this.disableEventCommands = false;
          break;
        default:
          activeComponent = '.....UNKNOWN....';
      }
    } else {
      activeComponent = 'YOU NEED TO BE LOGGED ON IN ORDER TO ACCESS THIS AREA';
    };


    return (
      <div>

        {breadcrumbComponent}




        <Pivot
          linkSize={PivotLinkSize.large}
          // linkFormat={PivotLinkFormat.tabs}
          onLinkClick={this.onPivotItemClick}
        >
          <PivotItem headerText="Events">
            {/* <Label>Clubs</Label> */}
          </PivotItem>
          <PivotItem headerText="Classes" />


          {
            <PivotItem headerText="Riders">
              {/* JAF TO BE DONE */ }
            </PivotItem>
          }
        </Pivot>
        {/*<h3>
          {selectedEvent.name}
        Event
        </h3>*/}
        {activeComponent}

      </div>
    );
  }
}


const mapStateToProps = state => ({
//  ClubId: state.ClubAdminReducer.ClubId,
//  events: state.ClubAdminReducer.Events,
//  loading: state.ClubAdminReducer.eventloading,
//  error: state.ClubAdminReducer.eventerror,
  sessionInfo: state.SessionInfoReducer.sessionInfo,
});


export default connect(mapStateToProps)(ClubAdmin);

// export default (ClubAdmin);
