/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

class Section extends Component {
  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
  }

  onFieldChange = (e) => {
    const { SectionDetails } = this.props;
    const mySectionDetails = SectionDetails;
    this.props.onFieldChange(mySectionDetails, e);
  }

  onEditClick(SectionDetails, EditStatus) {
    const mySectionDetails = SectionDetails;
    mySectionDetails.EditStatus = EditStatus;
    this.props.onEditStatusChange(mySectionDetails);
  }

  render() {
    // const sectionsArray = [];
    const renderOutput = [];

    if (this.props.SectionDetails.EditStatus === 'view') {
      // sectionsArray.push(
      renderOutput.push(
        <li id={this.props.SectionDetails.SectionId} key={this.props.SectionDetails.SectionId}>
          <div>{this.props.SectionDetails.SectionId}</div>
          <div>{this.props.SectionDetails.SectionName}</div>
          <div>{this.props.SectionDetails.GroupName}</div>
          <div>{this.props.SectionDetails.ObserverCode}</div>
          <div className="EventEdit" onClick={this.onEditClick.bind(this, this.props.SectionDetails, 'edit')}>Edit</div>
          <div>Delete</div>
        </li>,
      );
    } else if (this.props.SectionDetails.EditStatus === 'edit') {
      // sectionsArray.push(
      renderOutput.push(
        <li id={this.props.SectionDetails.SectionId} key={this.props.SectionDetails.SectionId}>
          <div>{this.props.SectionDetails.SectionId}</div>
          <div>
            <input type="text" name="SectionName" value={this.props.SectionDetails.SectionName} onChange={this.onFieldChange.bind(this)} />
          </div>
          <div>
            <input type="text" name="GroupName" value={this.props.SectionDetails.GroupName} onChange={this.onFieldChange.bind(this)} />
          </div>
          <div>
            <input type="text" name="ObserverCode" value={this.props.SectionDetails.ObserverCode} onChange={this.onFieldChange.bind(this)} />
          </div>
          <div className="EventEdit" onClick={this.onEditClick.bind(this, this.props.SectionDetails, 'view')}>Update</div>
          <div>Delete</div>
        </li>,
      );
    }
    return (
      <React.Fragment>
        {renderOutput}
      </React.Fragment>
    );
  }
}

export default Section;
