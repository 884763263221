import {
  FETCH_EVENTS_BEGIN,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
} from './ClubDetailsActionTypes';

const initialState = {
  eventitems: [],
  eventloading: false,
  eventerror: null,
};


function ClubDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTS_BEGIN:
      return {
        ...state,
        eventloading: true,
        eventerror: null,
      };

    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        eventloading: false,
        eventitems: action.payload.events,
      };

    case FETCH_EVENTS_FAILURE:
      return {
        ...state,
        eventloading: false,
        eventerror: action.payload.error,
        eventitems: [],
      };

    default:
      return state;
  }
}

export default ClubDetailsReducer;
