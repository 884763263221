import React, { Component } from 'react';
import { CompoundButton, Button, ButtonType } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { connect } from 'react-redux';
import { userLogon, userLogoff } from '../redux/SessionInfo/SessionInfoActions';

// eslint-disable-next-line react/prefer-stateless-function
class Login extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      userName: null,
      password: null
    };

    this.onTextChange = this.handleTextChange.bind(this);
    this.onLoginLogoutButtonClick = this.handleLoginLogoutButtonClick.bind(this);
  }

  open = () => this.setState({ isOpen: true })

  close = () => this.setState({ isOpen: false })

  componentWillReceiveProps(nextProps) {
    const {sessionInfo} = nextProps;
    this.state = { ...sessionInfo };

  }
  handleLoginLogoutButtonClick() {
    if (this.state.userName !== null) {
      {/*this.state = {
        isOpen: false,
        userName: null,
        password: null
      }; */ }
      this.props.dispatch(userLogoff({ userName: null, password: null }));
    } else {
      this.setState({ isOpen: true });

    }
  }

  handleTextChange(event) {
    const { id } = event.target;
    switch (id) {
      case 'userName':
        this.setState({userName: event.target.value})
        break;
      case 'password':
        this.setState({password: event.target.value})
        break;
      default:
      // unhandled formfield...
    }
  }


  LoginThenclose() {
    if (this.props.sessionInfo.userName === null) {
      const { userName, password } = this.state;
      this.props.dispatch(userLogon({ userName, password }));
    } else {
      this.props.dispatch(userLogoff({ userName: null, password: null }));
    }

    this.close();
  }


  render() {
    let buttonText = 'Login';
    const {userName, clubId, isSystemAdmin} = this.props.sessionInfo;
    let password;
    let loginForm = '';

    if (userName !== null) {
      buttonText = 'Logout, ' + userName
      // + ',' + clubId + ','+ isSystemAdmin


      // console.render(buttonText);
    } else {
      buttonText = 'Login';
    }

    return (
      <div>
        <div>
          <CompoundButton
            // secondaryText="You can login to your account here."
            onClick={() => this.onLoginLogoutButtonClick()}
          >
            {buttonText}
          </CompoundButton>
          {/* <Button height="60px" onClick={this.open}>Login</Button> */}
        </div>

        <Dialog
          ref = 'loginfrm'
          isOpen={this.state.isOpen}
          type={DialogType.close}
          onDismiss={this.close.bind(this)}
          title="Please enter login details"
            // subText="This is an example using Office Fabric"
          isBlocking={false}
          closeButtonArialLabel="Close"
        >
          <TextField
            id="userName"
            ref="UserName"
            label="UserName"
            onChange={this.onTextChange}
            //value={userName2}
            // onChange={this.handleChange}
            // disabled="false"
          />
          <TextField
            ref="Password"
            id="password"
            label="Password"
            type="password"
            onChange={this.onTextChange}
            // value={password2}
            // input type="password"              // onChange={this.onTextChange}
              // disabled="false"
          />
        <DialogFooter>
          <Button
            buttonType={ButtonType.primary}
            onClick={() => this.LoginThenclose()}
          >
            OK
          </Button>
        </DialogFooter>
        </Dialog>


      </div>

    );
  }
}

const mapStateToProps = state => ({
  sessionInfo: state.SessionInfoReducer.sessionInfo,
});

export default connect(mapStateToProps)(Login);

// export default Login;
