import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DetailsList, DetailsListLayoutMode } from 'office-ui-fabric-react/lib/DetailsList';

import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
// import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
// import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
// import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

import { fetchEventDetails } from '../redux/EventDetails/EventDetailsActions';

// eslint-disable-next-line react/prefer-stateless-function
class SectionsList extends Component {
  componentDidMount() {
    this.props.dispatch(fetchEventDetails());
  }

  render() {
    const { error, loading, eventSections } = this.props;

    if (error) {
      return <div>Error! error</div>;
    }

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="">
        <CommandBar
          items={[
            {
              key: 'Section',
              text: 'SECTION',
            },
            {
              key: 'newSection',
              text: 'New',
              iconProps: { iconName: 'Add' },
            },
            {
              key: 'editSection',
              text: 'Edit',
              iconProps: { iconName: 'Edit' },
            },
            {
              key: 'deleteSection',
              text: 'Delete',
              iconProps: { iconName: 'Delete' },
            },
          ]
    }


          ariaLabel={'Use left and right arrow keys to navigate between commands'}
        />
        <DetailsList
          setKey="SectionNo"
          items={eventSections}
          columns={[
            { key: 'column1', name: 'SectionNo', fieldName: 'sectionNo' },
            { key: 'column2', name: 'SectionName', fieldName: 'sectionName' },
            { key: 'column3', name: 'Group', fieldName: 'group' },
            { key: 'column4', name: 'ObserverKey', fieldName: 'observerKey' }
          ]}
          layoutMode={DetailsListLayoutMode.fixedColumns}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  eventSections: state.EventDetailsReducer.items.eventSections,
  loading: state.EventDetailsReducer.loading,
  error: state.EventDetailsReducer.error,
});


export default connect(mapStateToProps)(SectionsList);
