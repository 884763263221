import React, { Component } from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import {
  DetailsList,
  DetailsListLayoutMode,
  CheckboxVisibility,
  SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';


// eslint-disable-next-line react/prefer-stateless-function
class EventScores extends Component {
  constructor(props) {
    super(props);
    this.state = { eventScoresData: [] };

    this.onRefreshScoresClick = this.handleRefreshScoresClick.bind(this);
  }

  componentDidMount() {
    // this.getEventsScores();
    this.interval = setInterval(() => this.handleRefreshScoresClick(), 15000);
    const url = `${process.env.REACT_APP_API_URL}/EventsScores`;
    return fetch(url)
      // .then(this.handleErrors)
      .then(response => response.json())
      .then((data) => {
        this.setState({ eventScoresData: data });
      });
  }

  componentWillReceiveProps(nextProps) {
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getEventsScores() {
    const url = `${process.env.REACT_APP_API_URL}/EventsScores`;
    return fetch(url)
      // .then(this.handleErrors)
      .then(response => response.json())
      .then((data) => {
        this.setState({ eventScoresData: data })
      });
  }


  handleRefreshScoresClick() {
    const url = `${process.env.REACT_APP_API_URL}/EventsScores`;
    return fetch(url)
      // .then(this.handleErrors)
      .then(response => response.json())
      .then((data) => {
        this.setState({ eventScoresData: data });
      });
  }

  render() {
    const { eventId } = this.props;
    const { eventScoresData } = this.state;

    return (
      <div className="">
        <CommandBar
          items={[
            {
              key: 'eventScores',
              text: 'SCORES',
            },
            {
              key: 'refreshView',
              text: 'Refresh',
              iconProps: { iconName: 'Refresh' },
              onClick: this.onRefreshScoresClick,
            },
          ]
    }

        />
        <DetailsList
          checkboxVisibility={CheckboxVisibility.hidden}
          selectionMode={SelectionMode.single}
          selectionPreservedOnEmptyClick="true"
          setKey="EventsScores"
          // onActiveItemChanged={this.onSelectedEntryChanged}
          items={eventScoresData}
          columns={[
            { key: 'column5', name: 'No', fieldName: 'sectionNo', minWidth: 15, maxWidth: 30 },
            { key: 'column8', name: 'RiderNo', fieldName: 'riderNumber', minWidth: 48, maxWidth: 48, isResizable: true  },
            { key: 'column9', name: 'RiderName', fieldName: 'riderName', minWidth: 100, maxWidth: 100, isResizable: true  },
            { key: 'column10', name: 'Class', fieldName: 'className', minWidth: 60, maxWidth: 60, isResizable: true  },
            { key: 'column11', name: 'Score', fieldName: 'riderScore', minWidth: 40, maxWidth: 40, isResizable: true  },
            { key: 'column2', name: 'Key', fieldName: 'observationKey', minWidth: 50, maxWidth: 50, isResizable: true  },
            { key: 'column1', name: 'Id', fieldName: 'observationId', minWidth: 20, maxWidth: 20, isResizable: true },
            { key: 'column3', name: 'Club', fieldName: 'clubName', minWidth: 100, maxWidth: 100, isResizable: true  },
            { key: 'column4', name: 'Event', fieldName: 'eventName', minWidth: 100, maxWidth: 100, isResizable: true },
            { key: 'column6', name: 'SectionName', fieldName: 'sectionName', minWidth: 80, maxWidth: 80, isResizable: true },
            { key: 'column7', name: 'Group', fieldName: 'groupName', minWidth: 80, maxWidth: 80, isResizable: true  },

          ]}
          layoutMode={DetailsListLayoutMode.justified}
        />

      </div>
    );
  }
}

export default (EventScores);
