import {
  USER_LOGON_BEGIN,
  USER_LOGON_SUCCESS,
  USER_LOGON_FAILURE,
  USER_LOGOFF_SUCCESS,
} from './SessionInfoActionTypes';

const initialState = {
  sessionInfo:
    {
      userName: null,
      clubId: null,
    },
  loading: false,
  error: null,
};


function SessionInfoReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGON_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        sessionInfo: [],
      };

    case USER_LOGON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sessionInfo: action.payload.sessionInfo,
      };

    case USER_LOGON_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
        sessionInfo:
          {
            userName: null,
            clubId: null,
          },
      };

    case USER_LOGOFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sessionInfo:
          {
            userName: null,
            clubId: null,
            isSystemAdmin: null,
          },
      };

    default:
      return state;
  }
}

export default SessionInfoReducer;
