import React, { Component } from 'react';

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { DefaultButton } from 'office-ui-fabric-react';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';

class Entry extends Component {
  constructor(props) {
    super(props);
    this.state = props;
    // this.state = { formMode: props.formMode };


    this.onCancel = this.handleCancel.bind(this);
    // this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onClassChange = this.handleClassChange.bind(this);
    this.onSubmit = this.handleSubmit.bind(this);
    // this.onEditEventInDetailsClick = this.handleEditEventInDetailsClick.bind(this);
    // this.onDeleteEventInDetailsClick = this.handleDeleteEventInDetailsClick.bind(this);
  }

  componentDidMount() {
    // const {Event} = this.props;
    // this.setState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleCancel() {
    this.props.onCancelEntryClick();
    // this.setState({ formMode: 'viewEventInDetails' });
  }

  handleClassChange(event, item) {
    console.log(`Selection change: ${item.text} ${item.selected ? 'selected' : 'unselected'}`);

    const { Id } = this.state.selectedEntry;

    // this.setState({ selectedItem: item });
    this.setState(state => ({
      selectedEntry:
      {
        ...state.selectedEntry,
        classId: item.key,
      },
    }
    ));
  }

  handleChange(event) {
    const { id } = event.target;
    switch ( id ) {
      case 'riderNumber':
        this.setState(state => ({
          selectedEntry: {
            ...state.selectedEntry,
            riderNumber: event.target.value,
          },
        }));
        break;
      case 'riderName':
        this.setState(state => ({
          selectedEntry: {
            ...state.selectedEntry,
            riderName: event.target.value,
          },
        }));
        break;
      case 'classId':
        this.setState(state => ({
          selectedEntry: {
            ...state.selectedEntry,
            classId: event.target.value,
          },
        }));
        break;
      default:
      // unhandled formfield...
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { formMode } = this.state;
    const { selectedEntry } = this.state;
    // const paramSection = { formMode, ...selectedSection };

    this.props.onSubmitEntryClick(formMode, selectedEntry);

  }

  render() {
    let isViewOnly = true;
    let isDisableSubmit = false;
    let isDisableCancel = false;

    const { id, riderNumber, riderName, classId } = this.state.selectedEntry; // props.EventDetails;
    const { formMode } = this.state;
    const { eventClasses } = this.props;

    const columnProps = {
      tokens: { childrenGap: 5 },
      styles: { root: { width: 250 } },
    };

    let classOptions = eventClasses.map((thisClass, index) => {
      return (
        { key: thisClass.id, text: thisClass.className }
      );
    });


    // const { selectedItem } = { key: 'grape', text: 'Grape' };

    switch (formMode) {
      case 'newEntry':
        isViewOnly = false;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      case 'viewEntry':
        isViewOnly = true;
        isDisableSubmit = true;
        isDisableCancel = false;
        break;
      case 'editEntry':
        isViewOnly = false;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      case 'deleteEntry':
        isViewOnly = true;
        isDisableSubmit = false;
        isDisableCancel = false;
        break;
      default:
        this.disableEventCommands = false;
    }

    return (
      <div className="">
        {/*<h3>
          Form Mode:
          {formMode}
        </h3> */}
        <form
          className="eventForm"
          id="eventForm"
          onSubmit={this.onSubmit}
        >
          <Stack
            horizontal tokens={{ childrenGap: 50 }}
            styles={{ root: { width: 450 } }}
          >
            <Stack {...columnProps}>
              {/* label="User Name" */}


              <TextField
                id="riderNumber"
                label="RiderNumber"
                value={riderNumber}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />
              <TextField
                id="riderName"
                label="RiderName"
                value={riderName}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />
              <Dropdown
                label="Class"
                selectedKey={classId ? classId : undefined}
                onChange={this.onClassChange}
                placeholder="Select an option"
                options={classOptions}
                styles={{ dropdown: { width: 300 } }}
                disabled={isViewOnly}
              />
              <TextField
                id="classId"
                label="ClassId"
                value={classId}
                onChange={this.handleChange}
                disabled={isViewOnly}
              />
              <TextField
                id="entryId"
                label="EntryId"
                value={id}
                onChange={this.handleChange}
                disabled="true"
              />

            </Stack>
            <Stack {...columnProps}>
              {/* <Label>Click Create to complete</Label> */}
              <DefaultButton
                type="submit"
                data-automation-id="test"
                text="Submit"
                disabled={isDisableSubmit}
              />
              <DefaultButton
                // type=""
                // onClick="Cancel"
                onClick={this.props.onCancelEntryClick}
                text="Cancel"
                disabled={isDisableCancel}
              />
            </Stack>
          </Stack>
        </form>
      </div>
    );
  }
}

export default (Entry);
