import React, { Component } from 'react';
// import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Pivot, PivotItem, PivotLinkSize, PivotLinkFormat } from 'office-ui-fabric-react/lib/Pivot';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
// import { Label } from 'office-ui-fabric-react/lib/Label';
// import UsersList from './components/UsersList';
import Club from './components/Club';
import User from './components/Users/User';
import UsersList from './components/Users/UserList';
import ClubsList from './components/ClubsList';
import { setPageMode, deleteClub, deleteUser } from './redux/SystemAdmin/SystemAdminActions';


class Admin extends Component {
  constructor(props) {
    super(props);
    this.onPivotItemClick = this.handlePivotItemClick.bind(this);
    this.onNewClick = this.handleNewClick.bind(this);
    this.onEditClick = this.handleEditClick.bind(this);
    this.onDeleteClick = this.handleDeleteClick.bind(this);
  }

  handlePivotItemClick(item: PivotItem): void {
    // CLUBS | USERS | OTHER
    // alert(item.props.headerText);
    // pageViewMode = PivotItem.props.headerText;
    // this.setState({ pageviewMode: item.props.headerText });
    if (item.props.headerText=='Clubs') {
      this.props.dispatch(setPageMode({ pageviewMode: 'ViewClubs', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
      }));
    }
    if (item.props.headerText=='Users') {
      this.props.dispatch(setPageMode({ pageviewMode: 'ViewUsers', UserId: null, UserName: null, disableEdit: true, disableDelete: true,
      }));
    }
    if (item.props.headerText=='Other') {
      this.props.dispatch(setPageMode({ pageviewMode: 'Other', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
      }));
    }
  }


  handleNewClick(event) {
    if (this.props.pagemode.pageviewMode == 'ViewClubs') {
      this.props.dispatch(setPageMode({ pageviewMode: 'NewClub', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
      }));
    }

    if (this.props.pagemode.pageviewMode === 'ViewUsers') {
      this.props.dispatch(setPageMode({
        pageviewMode: 'NewUser',
        selectedUser: {
          clubUsers: [],
        },
        UserId: null,
        UserName: null,
        disableEdit: true,
        disableDelete: true,
      }));
    }

    // alert('Please confirm you wish to create New Club');
  }

  handleEditClick(event) {
    if (this.props.pagemode.pageviewMode === 'ViewClubs') {
    const { pageviewMode, ClubId, ClubName, disableEdit, disableDelete} = this.props.pagemode;
    //alert('Please confirm you wish to Edit: ' + this.state.ClubName);
    this.props.dispatch(setPageMode({ pageviewMode: 'EditClub',  ClubId: ClubId, ClubName: ClubName, disableEdit: true, disableDelete: true, }));
    }
    if (this.props.pagemode.pageviewMode === 'ViewUsers') {
    const { pageviewMode, selectedUser, UserId, UserName, disableEdit, disableDelete} = this.props.pagemode;
    //alert('Please confirm you wish to Edit: ' + this.state.ClubName);
    this.props.dispatch(setPageMode({ pageviewMode: 'EditUser', selectedUser, UserId: UserId, UserName: UserName, disableEdit: true, disableDelete: true, }));
    }
  }

  handleDeleteClick(event) {
    //alert('Please confirm you wish to delete: ' + this.props.pagemode.ClubId);
    if (this.props.pagemode.pageviewMode == 'ViewClubs') {
    this.props.dispatch(deleteClub(this.props.pagemode.ClubId));

    this.props.dispatch(setPageMode({ pageviewMode: 'ViewClubs', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
    }));
    }

    if (this.props.pagemode.pageviewMode == 'ViewUsers') {
    this.props.dispatch(deleteUser(this.props.pagemode.UserId));

    this.props.dispatch(setPageMode({ pageviewMode: 'ViewUsers', UserId: null, UserName: null, disableEdit: true, disableDelete: true,
    }));
    }

  }

  render() {
    let activeComponent;
  const { pageviewMode, ClubId, ClubName, disableEdit, disableDelete} = this.props.pagemode;

  const {userName, clubId, isSystemAdmin} = this.props.sessionInfo;

if (isSystemAdmin===true) {
    switch (pageviewMode) {
      case 'ViewClubs':
        activeComponent = <ClubsList />;
        break;
      case 'NewClub':
        activeComponent = <Club />;
        break;
      case 'EditClub':
        activeComponent = <Club />;
        break;
      case 'ViewUsers':
        activeComponent = <UsersList />;
        break;
      case 'NewUser':
        activeComponent = <User />;
        break;
      case 'EditUser':
        activeComponent = <User />;
        break;
      case 'Other':
        activeComponent = 'OTHER';
        break;
      default:
        activeComponent = 'UNKNOWN....';
    }
  } else {
    activeComponent = 'YOU NEED TO LOGIN TO ACCESS THIS AREA OF THE WEBSITE';
  }

    return (
      <div>
        <h2>SYSTEMS ADMIN</h2>
        { /* <Club />  */ }
        { /* <h3>Clubs</h3> */ }
        <Pivot
          linkSize={PivotLinkSize.large}
          linkFormat={PivotLinkFormat.tabs}
          onLinkClick={this.onPivotItemClick}
        >
          <PivotItem headerText="Clubs">
            {/* <Label>Clubs</Label> */}
          </PivotItem>
          <PivotItem headerText="Users">
          </PivotItem>
          {/*<PivotItem headerText="Other">
          </PivotItem> */}
        </Pivot>


 <CommandBar
          items={[
            {
              key: 'newItem',
              name: 'New',
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'Add',
              },
              ariaLabel: 'New',
              onClick: () => this.onNewClick(),
            },
            {
              key: 'editItem',
              name: 'Edit',
              disabled: disableEdit,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'Edit',
              },
              ariaLabel: 'Edit',
              onClick: () => this.onEditClick(),
            },
            {
              key: 'deleteItem',
              name: 'Delete',
              disabled: disableDelete,
              cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
              iconProps: {
                iconName: 'Delete',
              },
              ariaLabel: 'Delete',
              onClick: () => this.onDeleteClick(),
            },
          ]
          }
        />


        {activeComponent}

      </div>
    );
  }
}


const mapStateToProps = state => ({
  // eventSections: state.EventDetailsReducer.items.eventSections
  clubs: state.SystemAdminReducer.clubs,
  loading: state.SystemAdminReducer.loading,
  error: state.SystemAdminReducer.error,
  NewClubAdded: state.SystemAdminReducer.NewClubAdded,
  pagemode: state.SystemAdminReducer.pagemode,
  sessionInfo: state.SessionInfoReducer.sessionInfo,
});


export default connect(mapStateToProps)(Admin);
