// align naming to API methods...
// GET    read
// POST   create
// PUT    update-replace
// PATCH  update-modify - do not use....
// DELETE delete

export const USER_LOGON_BEGIN = 'USER_LOGON_BEGIN';
export const USER_LOGON_SUCCESS = 'USER_LOGON_SUCCESS';
export const USER_LOGON_FAILURE = 'USER_LOGON_FAILURE';

export const USER_LOGOFF_SUCCESS = 'USER_LOGOFF_SUCCESS';
