/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';

import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
// import Section from './Section';
import SectionsList from './components/SectionsList';
import EntriesList from './components/EntriesList';
import EventsList from './components/Events/EventsList';
import { Pivot, PivotItem, PivotLinkSize } from 'office-ui-fabric-react/lib/Pivot';
// import { Label } from 'office-ui-fabric-react/lib/Label';
import { connect } from 'react-redux';

initializeIcons();

class ConnectedEvent extends Component {
  static updateState(prevState, SectionDetails, name, value) {
    const newItems = [...prevState.Sections];
    const index = SectionDetails.SectionId - 1;
    switch (name) {
      case 'SectionName':
        newItems[index].SectionName = value;
        break;
      case 'GroupName':
        newItems[index].GroupName = value;
        break;
      case 'ObserverCode':
        newItems[index].ObserverCode = value;
        break;
      default:
    }
    return ({ newItems });
  }

  constructor(props) {
    super(props);
    this.onSectionEditClick = this.onSectionEditClick.bind(this);
    this.onSectionFieldChange = this.onSectionFieldChange.bind(this);
  }

  onSectionEditClick(SectionDetails) {
    const { Sections } = this.state;
    const myState = Sections;
    const Index = SectionDetails.SectionId - 1;
    if (SectionDetails.EditStatus === 'edit') {
      myState[Index].EditStatus = 'edit';
    } else if (SectionDetails.EditStatus === 'update') {
      myState[Index].EditStatus = 'view';
    }
    // need to use alternate form for setState (prevState)
    this.setState({ Sections: myState });
  }

  onSectionFieldChange(SectionDetails, e) {
    const { name, value } = e.target;
    this.setState(prevState => Event.updateState(prevState, SectionDetails, name, value));
  }

  render() {
    return (
      <div>

        <h2>CLUB ADMIN:&nbsp;Manx Youth Trials Club</h2>
        <h3>
          EVENT DETAILS: 2019 Championship - Round One
          { /* myEventName */}
        </h3>
        <Pivot linkSize={PivotLinkSize.large}>
          <PivotItem headerText="Club Events"></PivotItem>
          <PivotItem headerText="Sections"></PivotItem>
          <PivotItem headerText="Entries"></PivotItem>
        </Pivot>
        <EventsList />
        { /* <h3>Sections</h3> */ }
        <SectionsList />
        { /* <h3>Entries</h3> */ }
        <EntriesList />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    EventName: state.EventName,
    Sections: state.Sections,
  };
};


const Event = connect(mapStateToProps)(ConnectedEvent);
// not defining mapDispatchToProps because List doesn't update Store
// presumably?

export default Event;
