import {
  GET_CLUBS_BEGIN,
  GET_CLUBS_SUCCESS,
  GET_CLUBS_FAILURE,
  POST_CLUB_BEGIN,
  POST_CLUB_SUCCESS,
  POST_CLUB_FAILURE,
  PUT_CLUB_BEGIN,
  PUT_CLUB_SUCCESS,
  PUT_CLUB_FAILURE,
  DELETE_CLUB_BEGIN,
  DELETE_CLUB_SUCCESS,
  DELETE_CLUB_FAILURE,
  GET_USERS_BEGIN,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  POST_USER_BEGIN,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  PUT_USER_BEGIN,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SET_PAGE_MODE,
} from './SystemAdminActionTypes';

// eslint-disable-next-line import/prefer-default-export


export function putClubBegin(updateClub) {
  return {
    type: PUT_CLUB_BEGIN,
    payload: { updateClub },
  };
}

export function putClubSuccess(updateClub) {
  return {
    type: PUT_CLUB_SUCCESS,
    payload: { updateClub },
  };
}

export function putClubFailure(error) {
  return {
    type: PUT_CLUB_FAILURE,
    payload: { error },
  };
}

export function putClub(updateClub) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        id: updateClub.Club.id,
        name: updateClub.Club.name,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(putClubBegin(updateClub));
    const url = `${process.env.REACT_APP_API_URL}/admin/Clubs`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(putClubSuccess(json));
        return json;
      })
      .catch(error => dispatch(putClubFailure(error)));
  };
}

export function deleteClubBegin(clubId) {
  return {
    type: DELETE_CLUB_BEGIN,
    payload: { clubId },
  };
}

export function deleteClubSuccess(clubId) {
  return {
    type: DELETE_CLUB_SUCCESS,
    payload: { clubId },
  };
}

export function deleteClubFailure(error) {
  return {
    type: DELETE_CLUB_FAILURE,
    payload: { error },
  };
}

export function deleteClub(clubId) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      body: JSON.stringify({ id: clubId }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(deleteClubBegin(clubId));
    const url = `${process.env.REACT_APP_API_URL}/admin/Clubs/{clubId}`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(deleteClubSuccess(json));
        return json;
      })
      .catch(error => dispatch(deleteClubFailure(error)));
  };
}

export function setPageMode(pagemode) {
  return {
    type: SET_PAGE_MODE,
    payload: { pagemode },
  };
}




export function getClubsBegin() {
  return {
    type: GET_CLUBS_BEGIN,
  };
}

export function getClubsSuccess(clubs) {
  return {
    type: GET_CLUBS_SUCCESS,
    payload: { clubs },
  };
}

export function getClubsFailure(error) {
  return {
    type: GET_CLUBS_FAILURE,
    payload: { error },
  };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function getClubs() {
  return (dispatch) => {
    dispatch(getClubsBegin());
    const url = `${process.env.REACT_APP_API_URL}/admin/Clubs`;
    return fetch(url)
      .then(handleErrors)
      .then(response => response.json())
      .then((json) => {
        dispatch(getClubsSuccess(json));
        return json;
      })
      .catch(error => dispatch(getClubsFailure(error)));
  };
}

export function postClubBegin(NewClubName) {
  return {
    type: POST_CLUB_BEGIN,
    payload: { NewClubName },
  };
}

export function postClubSuccess(clubId) {
  return {
    type: POST_CLUB_SUCCESS,
    payload: { clubId },
  };
}

export function postClubFailure(error) {
  return {
    type: POST_CLUB_FAILURE,
    payload: { error },
  };
}

export function postClub(NewClubName) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({
        "id": 0,
        "name": NewClubName,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(postClubBegin(NewClubName));
    const url = `${process.env.REACT_APP_API_URL}/admin/Clubs`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(postClubSuccess(json));
        return json;
      })
      .catch(error => dispatch(postClubFailure(error)));
  };
}


// USERS
export function putUserBegin(updateUser) {
  return {
    type: PUT_USER_BEGIN,
    payload: { updateUser },
  };
}

export function putUserSuccess(updateUser) {
  return {
    type: PUT_USER_SUCCESS,
    payload: { updateUser },
  };
}

export function putUserFailure(error) {
  return {
    type: PUT_USER_FAILURE,
    payload: { error },
  };
}

export function putUser(updateUser) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      body: JSON.stringify(updateUser),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(putUserBegin(updateUser));
    const url = `${process.env.REACT_APP_API_URL}/admin/Users`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(putUserSuccess(json));
        return json;
      })
      .catch(error => dispatch(putUserFailure(error)));
  };
}

export function deleteUserBegin(UserId) {
  return {
    type: DELETE_USER_BEGIN,
    payload: { UserId },
  };
}

export function deleteUserSuccess(UserId) {
  return {
    type: DELETE_USER_SUCCESS,
    payload: { UserId },
  };
}

export function deleteUserFailure(error) {
  return {
    type: DELETE_USER_FAILURE,
    payload: { error },
  };
}

export function deleteUser(UserId) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      body: JSON.stringify({ id: UserId }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(deleteUserBegin(UserId));
    const url = `${process.env.REACT_APP_API_URL}/admin/Users/{UserId}`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(deleteUserSuccess(json));
        return json;
      })
      .catch(error => dispatch(deleteUserFailure(error)));
  };
}


export function getUsersBegin() {
  return {
    type: GET_USERS_BEGIN,
  };
}

export function getUsersSuccess(users) {
  return {
    type: GET_USERS_SUCCESS,
    payload: { users },
  };
}

export function getUsersFailure(error) {
  return {
    type: GET_USERS_FAILURE,
    payload: { error },
  };
}

export function getUsers() {
  return (dispatch) => {
    dispatch(getUsersBegin());
    const url = `${process.env.REACT_APP_API_URL}/admin/users`;
    return fetch(url)
      .then(handleErrors)
      .then(response => response.json())
      .then((json) => {
        dispatch(getUsersSuccess(json));
        return json;
      })
      .catch(error => dispatch(getUsersFailure(error)));
  };
}

export function postUserBegin(NewUserName) {
  return {
    type: POST_USER_BEGIN,
    payload: { NewUserName },
  };
}

export function postUserSuccess(UserId) {
  return {
    type: POST_USER_SUCCESS,
    payload: { UserId },
  };
}

export function postUserFailure(error) {
  return {
    type: POST_USER_FAILURE,
    payload: { error },
  };
}

export function postUser(NewUser) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      body: JSON.stringify(NewUser),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(postUserBegin(NewUser));
    const url = `${process.env.REACT_APP_API_URL}/admin/Users`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(postUserSuccess(json));
        return json;
      })
      .catch(error => dispatch(postUserFailure(error)));
  };
}