import React, { Component } from 'react';
import Login from './Login';

// eslint-disable-next-line react/prefer-stateless-function
class HeaderBar extends Component {
  render() {
    return (
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm2">
          <img src="media/bike.jpg" alt="ObservedTrialsLive" height="60" />
        </div>
        <div className="ms-Grid-col ms-sm8">
          <img src="media/ObservedTrials_Logo.jpg" alt="ObservedTrialsLive" height="60px"/>
        </div>
        <div className="ms-Grid-col ms-sm2">
          <Login />
        </div>
      </div>
    );
  }
}

export default HeaderBar;
