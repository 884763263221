import {
  GET_CLUBS_BEGIN,
  GET_CLUBS_SUCCESS,
  GET_CLUBS_FAILURE,
  POST_CLUB_BEGIN,
  POST_CLUB_SUCCESS,
  POST_CLUB_FAILURE,
  PUT_CLUB_BEGIN,
  PUT_CLUB_SUCCESS,
  PUT_CLUB_FAILURE,
  DELETE_CLUB_BEGIN,
  DELETE_CLUB_SUCCESS,
  DELETE_CLUB_FAILURE,
  GET_USERS_BEGIN,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  POST_USER_BEGIN,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  PUT_USER_BEGIN,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SET_PAGE_MODE,
} from './SystemAdminActionTypes';


const initialState = {
  clubs: [],
  users: [],
  loading: false,
  error: null,
  NewClubAdded: 0,
  pagemode: {
    pageviewMode: 'ViewClubs',
    selectedUser: {
      clubUser: null,
    },
    ClubId: null,
    ClubName: null,
    disableEdit: true,
    disableDelete: true,
  },
};


function SystemAdminReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLUBS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_CLUBS_SUCCESS:
      return {
        ...state,
        loading: false,
        clubs: action.payload.clubs,
        NewClubAdded: 0,
      };

    case GET_CLUBS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };
    case POST_CLUB_BEGIN:
      return {
        ...state,
      };
    case POST_CLUB_SUCCESS:
      return {
        ...state,
        NewClubAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewClubs', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
          }
      };
    case POST_CLUB_FAILURE:
      return {
        ...state,
      };

    case SET_PAGE_MODE:
      return {
        ...state,
        pagemode: action.payload.pagemode,
      };

    case DELETE_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CLUB_SUCCESS:
      return {
        ...state,
        NewClubAdded: 1,
        loading: false,
        pagemode:
          {
            pageviewMode: 'ViewClubs', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
          }
      };

    case DELETE_CLUB_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case PUT_CLUB_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PUT_CLUB_SUCCESS:
      return {
        ...state,
        loading: true,
        NewClubAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewClubs', ClubId: null, ClubName: null, disableEdit: true, disableDelete: true,
          },
      };
    case PUT_CLUB_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_USERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        NewUserAdded: 0,
      };

    case GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };
    case POST_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case POST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        NewUserAdded: 1,
        pagemode:
        {
          pageviewMode: 'ViewUsers', UserId: null, UserName: null, disableEdit: true, disableDelete: true,
        },
      };
    case POST_USER_FAILURE:
      return {
        ...state,
      };

    case DELETE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        NewUserAdded: 1,
        loading: false,
        pagemode:
          {
            pageviewMode: 'ViewUsers', UserId: null, UserName: null, disableEdit: true, disableDelete: true,
          }
      };

    case DELETE_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case PUT_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        NewUserAdded: 1,
        pagemode:
          {
            pageviewMode: 'ViewUsers', UserId: null, UserName: null, disableEdit: true, disableDelete: true,
          },
      };
    case PUT_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export default SystemAdminReducer;
