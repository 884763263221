import {
  FETCH_EVENTDETAILS_BEGIN,
  FETCH_EVENTDETAILS_SUCCESS,
  FETCH_EVENTDETAILS_FAILURE,
} from './EventDetailsActionTypes';

// eslint-disable-next-line import/prefer-default-export

export function fetchEventDetailsBegin() {
  return {
    type: FETCH_EVENTDETAILS_BEGIN,
  };
}

export function fetchEventDetailsSuccess(eventDetails) {
  return {
    type: FETCH_EVENTDETAILS_SUCCESS,
    payload: { eventDetails },
  };
}

export function fetchEventDetailsFailure(error) {
  return {
    type: FETCH_EVENTDETAILS_FAILURE,
    payload: { error },
  };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function fetchEventDetails() {
  return (dispatch) => {
    dispatch(fetchEventDetailsBegin());
    const url = `${process.env.REACT_APP_API_URL}/events/1`;
    return fetch(url)
    // return fetch('http://api.observedtrials.live/1.0/events')
      .then(handleErrors)
      .then(response => response.json())
      .then((json) => {
        dispatch(fetchEventDetailsSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchEventDetailsFailure(error)));
  };
}
