/* eslint-disable import/no-extraneous-dependencies */
import {
  createStore,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const store = createStore(
  rootReducer,
  applyMiddleware(thunk),
);
// This creates the storeand binds to rootReducer
// rootReducer is responsible for procesing the Actions and
// determining the final state

export default store;
