import {
  POST_EVENT_BEGIN,
  POST_EVENT_SUCCESS,
  POST_EVENT_FAILURE,
  PUT_EVENT_BEGIN,
  PUT_EVENT_SUCCESS,
  PUT_EVENT_FAILURE,
  DELETE_EVENT_BEGIN,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,

} from './ClubAdminActionTypes';

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function postEventBegin(NewEventName) {
  return {
    type: POST_EVENT_BEGIN,
    payload: { NewEventName },
  };
}

export function postEventSuccess(eventId) {
  return {
    type: POST_EVENT_SUCCESS,
    payload: { eventId },
  };
}

export function postEventFailure(error) {
  return {
    type: POST_EVENT_FAILURE,
    payload: { error },
  };
}

export function postEvent(NewEventName) {
  return (dispatch) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ ...NewEventName }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(postEventBegin(NewEventName));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Events`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(postEventSuccess(json));
        return json;
      })
      .catch(error => dispatch(postEventFailure(error)));
  };
}

export function putEventBegin(updateEvent) {
  return {
    type: PUT_EVENT_BEGIN,
    payload: { updateEvent },
  };
}

export function putEventSuccess(updateEvent) {
  return {
    type: PUT_EVENT_SUCCESS,
    payload: { updateEvent },
  };
}

export function putEventFailure(error) {
  return {
    type: PUT_EVENT_FAILURE,
    payload: { error },
  };
}

export function putEvent(updateEvent) {
  return (dispatch) => {
    const options = {
      method: 'PUT',
      body: JSON.stringify({ ...updateEvent }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(putEventBegin(updateEvent));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Events`;
    return fetch(url, options)
      .then(handleErrors)
      //.then(response => response.json())
      .then((json) => {
        dispatch(putEventSuccess(json));
        return json;
      })
      .catch(error => dispatch(putEventFailure(error)));
  };
}

export function deleteEventBegin(eventId) {
  return {
    type: DELETE_EVENT_BEGIN,
    payload: { eventId },
  };
}

export function deleteEventSuccess(eventId) {
  return {
    type: DELETE_EVENT_SUCCESS,
    payload: { eventId },
  };
}

export function deleteEventFailure(error) {
  return {
    type: DELETE_EVENT_FAILURE,
    payload: { error },
  };
}

export function deleteEvent(eventId) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      body: JSON.stringify({ id: eventId }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    dispatch(deleteEventBegin(eventId));
    const url = `${process.env.REACT_APP_API_URL}/ClubAdmin/Events/${eventId}`;
    return fetch(url, options)
      .then(handleErrors)
      // .then(response => response.json())
      .then((json) => {
        dispatch(deleteEventSuccess(json));
        return json;
      })
      .catch(error => dispatch(deleteEventFailure(error)));
  };
}
